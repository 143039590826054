<template>
  <VpShell :aside="false" class="vp-h-full vp-overflow-hidden">
    <template #default>
      <div class="vp-flex vp-flex-row vp-h-full">
        <div
          class="vp-border-gray vp-border-r vp-w-[350px] vp-overflow-y-scroll vp-shrink-0 vp-grow-0"
        >
          <VpList
            :columns="[]"
            endpoint="chats"
            :filters.sync="filters"
            ref="list"
            :request-payload="{ query: $options.chats, noCache: true }"
            class="vp-h-full"
            :settings="false"
            :infinite="true"
            feature="CHAT"
            v-if="isAuthorized"
            cache="chats"
          >
            <template #empty>
              <VpFeature the-key="CHAT" #default="{ feature }">
                <VpListEmpty :feature="feature" />
              </VpFeature>
            </template>

            <template #filters>
              <VpField label="Funnel">
                <FunnelInput v-model="filters.funnelId" />
              </VpField>

              <VpField label="Tags">
                <TagsInput v-model="filters.tagIds" type="contact" />
              </VpField>
            </template>

            <template #default="{ items }">
              <VpConnection
                v-for="item in items"
                :type="item.contactType"
                :class="{ 'vp-bg-gray-100': id == item.id }"
                class="vp-cursor-pointer vp-p-2 vp-border-t vp-border-gray-200 hover:vp-ring-4 vp-ring-inset vp-ring-gray-100"
                :data="item"
                :key="item.id"
                @click.native="open(item)"
              />
            </template>
          </VpList>
          <VpUnauthorizedAccess
            v-if="!isAuthorized"
            :from-extension="true"
            class="vp-p-2"
          ></VpUnauthorizedAccess>
        </div>
        <div class="vp-w-full vp-bg-gray-100">
          <router-view
            :contact="contact"
            :key="contact ? contact.id : 'intro'"
            @refresh="$refs.list.refresh()"
          ></router-view>
        </div>
      </div>
    </template>
  </VpShell>
</template>

<script>
import { mapGetters } from "vuex";
import TagsInput from "components/src/tags/input.vue";
import FunnelInput from "components/src/funnel/input.vue";
import chats from "@/views/crm/chats/collection.gql";

export default {
  chats,
  props: {
    id: [String, Number],
  },
  components: {
    TagsInput,
    FunnelInput,
  },
  data() {
    return {
      contact: null,
      chatSearchQuery: null,
      filters: {
        funnelId: null,
        tagIds: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    isAuthorized() {
      return this.checkPermission("CHAT", "read");
    },
  },
  methods: {
    open(contact) {
      this.contact = contact;
      this.$router.push({
        name: "conversation",
        params: { id: contact.id },
      });
    },
  },
};
</script>
