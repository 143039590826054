<template>
  <div
    class="vp-grid vp-h-full vp-items-stretch"
    :class="{
      'vp-grid-cols-[64px,1fr]': collapse,
      'vp-grid-cols-[208px,1fr]': aside,
      'vp-grid-cols-[1fr]': !aside,
      'vp-grid-cols-[0px,1fr]': !isAsideOpen,
    }"
  >
    <!-- Aside -->
    <aside v-if="aside" class="vp-overflow-auto">
      <slot name="nav" :toggle-aside="toggleAside" />
    </aside>

    <!-- Main -->
    <main class="vp-overflow-auto">
      <slot :toggle-aside="toggleAside" />
    </main>
  </div>
</template>

<script>
import bus from "utils/bus.js";

export default {
  props: {
    collapse: Boolean,
    aside: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    bus.$on("aside-toggle", () => {
      this.toggleAside();
    });
  },

  data() {
    return {
      isAsideOpen: this.aside,
    };
  },

  methods: {
    toggleAside() {
      this.isAsideOpen = !this.isAsideOpen;
    },
  },
};
</script>
