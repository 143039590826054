<template>
  <div
    class="vp-p-12 vp-text-center vp-flex vp-justify-center vp-items-center vp-flex-col vp-max-w-md vp-mx-auto"
  >
    <RippleIcon :icon="icon" />

    <h3 class="vp-mt-4 vp-font-bold vp-text-2xl vp-text-black">
      {{ title }}
    </h3>

    <div class="vp-text-gray-500">
      <slot name="desc">
        <p>{{ desc }}</p>
      </slot>
    </div>
    <div class="vp-mt-4">
      <slot name="actions">
        <VyButton
          type="button"
          v-if="label && to"
          :label="label"
          :to="to"
          class="button--primary"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import RippleIcon from "./ripple-icon.vue";

export default {
  components: {
    RippleIcon,
  },
  props: {
    to: Object,
    title: String,
    desc: String,
    label: String,
    icon: {},
  },
};
</script>
