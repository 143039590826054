<template>
  <div
    class="vp-flex vp-flex-col vp-p-6 vp-border vp-border-gray-300 vp-rounded-md"
  >
    <h2 class="vp-relative vp-text-gray-400 vp-text-4xl vp-font-bold">
      {{ no }}
    </h2>
    <h4 class="vp-text-lg vp-font-bold" v-if="title">{{ title }}</h4>
    <div class="vp-flex-auto vp-mb-4">
      <slot />
    </div>
    <div class="vp-mt-auto">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    no: Number,
    title: String,
  },
};
</script>
