<template>
  <VpShell
    class="vp-h-full"
    left-size="200px"
    :left-classlist="['vp-border-r']"
    :aside="aside"
  >
    <!-- list-layout -->
    <template v-if="aside" #nav>
      <div class="vp-border-r vp-border-gray-200 vp-h-full">
        <slot name="left" />
      </div>
    </template>

    <template #default>
      <portal to="header">
        <div :key="route" class="vp-flex vp-space-x-2">
          <slot name="header-start" />
          <VpButtonSm
            v-if="addRoute"
            :icon="$options.icons.Add"
            @click.native="canChangeRoute()"
            :label="addLabel"
          />
          <VpButtonSm
            v-if="onAdd"
            :icon="$options.icons.Add"
            @click.native="onAdd"
            :label="addLabel"
          />

          <slot name="header-end" />
        </div>
      </portal>
      <slot />
    </template>
  </VpShell>
</template>

<script>
import { mapGetters } from "vuex";
import { Add } from "icons/icons.js";

export default {
  icons: {
    Add,
  },
  props: {
    aside: {
      type: Boolean,
      default: false,
    },
    addRoute: [String, Object],
    onAdd: Function,
    addLabel: {
      type: String,
      default: "Add New",
    },
    feature: String,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
    }),
    route() {
      return typeof this.addRoute === "string"
        ? this.addRoute
        : this.addRoute?.name;
    },
  },
  methods: {
    canChangeRoute() {
      if (this.feature) {
        this.checkPermission(this.feature, "upsert", () => {
          this.isSubscriptionLimitExceeded(this.feature, false, null, () => {
            this.$router.push(this.addRouteObject());
          });
        });
      } else {
        this.$router.push(this.addRouteObject());
      }
    },
    addRouteObject() {
      if (typeof this.addRoute === "string") {
        return { name: this.addRoute, params: { id: "+" } };
      } else {
        return { name: this.addRoute?.name, params: this.addRoute?.params };
      }
    },
  },
};
</script>
