<template>
  <Portal to="slideover-container" slim>
    <div
      :id="id"
      class="vp-w-52 vp-fixed vp-bg-white vp-flex-col vp-flex vp-transition vp-transform vp-top-0 vp-bottom-0 vp-z-50"
      :class="[
        {
          'vp-drop-shadow-2xl vp-translate-x-0': isOpen,
          'vp-translate-x-full': !isOpen,
          'vp-left-0': position == 'left',
          'vp-right-0': position == 'right',
        },
      ]"
      :style="{ width: size + 'px' }"
    >
      <div
        class="vp-flex vp-items-center vp-justify-between vp-border-gray-200 vp-border-b"
      >
        <p class="vp-text-gray-500 vp-text-sm vp-font-semibold vp-px-4">
          {{ title }}
        </p>
        <button
          class="vp-w-12 vp-h-12 vp-flex vp-items-center vp-justify-center vp-cursor-pointer vp-group"
          @click="close(id)"
        >
          <Icon
            class="vp-text-gray-500 group-hover:vp-text-gray-900"
            :name="$options.icons.Close"
          />
        </button>
      </div>

      <!-- Loading -->
      <div
        class="vp-flex vp-justify-center vp-items-center vp-h-full"
        v-if="loading"
      >
        <Icon
          name="MdiLoading"
          class="vp-animate vp-animate-spin vp-w-8 vp-h-8 vp-text-gray-300"
        />
      </div>

      <!-- Slots -->
      <template v-else>
        <div class="vp-flex-grow vp-overflow-auto">
          <slot />
        </div>

        <div v-if="$scopedSlots.footer" class="vp-border-t vp-p-2">
          <slot name="footer" />
        </div>
      </template>
    </div>
  </Portal>
</template>

<script>
import bus from "utils/bus.js";
import { Close } from "icons/icons.js";
import Icon from "../icon.vue";

const slideoverPositionClass = {
  left: "slideover--left",
  right: "slideover--right",
};

export default {
  slideoverPositionClass,
  icons: {
    Close,
  },
  components: {
    Icon,
  },
  props: {
    id: String,
    size: {
      type: Number,
      default: 200,
    },
    position: {
      type: String,
      default: "right",
    },
    title: String,
    loading: Boolean,
  },

  data() {
    return {
      isOpen: false,
      scope: null,
      autoClose: true,
    };
  },

  mounted() {
    bus.$on("slideover-open", (id, scope) => {
      this.open(id);
      this.scope = scope;
    });

    bus.$on("slideover-close", (id) => {
      this.close(id);
      this.scope = null;
    });

    bus.$on("slideover-toggle", (id) => {
      this.toggle(id);
    });
  },

  methods: {
    open(id) {
      if (!id) return;
      if (this.id == id) {
        this.isOpen = true;
        this.$emit("open");
      } else {
        this.isOpen = false;
      }
    },
    close(id) {
      let shouldClose = false;
      if (id) {
        if (this.id == id) {
          shouldClose = true;
        }
      } else {
        shouldClose = true;
      }
      if (shouldClose) {
        this.isOpen = false;
        this.$emit("close");
      }
    },
    toggle(id) {
      if (this.id == id) {
        bus.$emit("slideover-close");
        if (this.isOpen) {
          this.close(id);
        } else {
          this.open(id);
        }
      }
    },
  },
};
</script>
