import { startCase } from "lodash-es";
import { treatment } from "./split";
const IS_SEGMENT = import.meta.env.VITE_SEGMENT === "true";

export const init = (data) => {
  if (!IS_SEGMENT) return;

  window.analytics.identify(data.userId, {
    Name: data.userName,
    Email: data.email,
    email: data.email,
    "Subscription Plan ID": data.subscriptionPlanId,
    "Subscription Plan": data.subscriptionPlan,
    "Subscription Plan Validity": data.subscriptionPlanValidity,
    "Email Verified": data.emailVerified,
    "Last Active": data.lastActive,
    "Last Open In Web": data.lastActive,
    "User Created At": data.userCreatedAt,
    "Web Version": data.version,
    Platform: data.platform,
    utm_campaign: data.utm_campaign,
    utm_content: data.utm_content,
    utm_medium: data.utm_medium,
    utm_source: data.utm_source,
    utm_term: data.utm_term,
    referrer: data.referrer,
    Credit: data.credits,
    // Freshwork BY Kaushal Gajjar
    firstName: data.userName,
    lastName: "",
    cfSubscriptionPlan: `${data.subscriptionPlan} - ${data.subscriptionPlanId}`,
    country: data.country,
  });

  window.analytics.group(data.campaignId, {
    Name: data.campaignName,
    Description: data.desc,
    Subdomain: data.subdomain,
    Address: data.address,
    Country: data.country,
    Credit: data.credits,
    "Web Version": data.version,
  });
};

const pageName = (route) => {
  if (route.params.id) {
    if (route.params.id === "+") {
      return `Create: ${startCase(route.name)}`;
    } else {
      return `Edit: ${startCase(route.name)}`;
    }
  }
  return startCase(route.name);
};

export const pageView = (to, from) => {
  if (!IS_SEGMENT) return;

  treatment("Segment_page_view_tracking", () => {
    window.analytics.page(
      pageName(to),
      {
        path: to.fullPath, // In built variable of segment
        "From Name": from ? pageName(from) : "",
        "From Path": from ? from.fullPath : "",
      },
      {
        integrations: {
          All: true,
          "OneSignal (New)": false,
        },
      }
    );
  });
};
