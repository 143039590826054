<template>
  <RouterLink
    class="vp-cursor-pointer"
    v-if="product === 'web'"
    :to="{ name: 'credits' }"
    v-slot="{ isActive }"
  >
    <VyButton
      :icon="$options.icons.Credit"
      class="nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
      :to="{ name: 'credits' }"
      :class="{
        'vp-text-white vp-border-l-[4px] vp-border-primary-500': isActive,
        '!vp-text-danger-500': credits <= 0,
        '!vp-text-warning-500': (credits <= 10) & (credits > 0),
      }"
    >
      <div class="vp-text-center">
        Credits
        <div v-if="credits">
          {{ credits }}
        </div>
      </div>
    </VyButton>
  </RouterLink>

  <VyButton
    v-else
    :icon="$options.icons.Credit"
    class="nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
    :href="`${$options.VITE_WEB_URL}/redirect?to=credits`"
    :class="{
      '!vp-text-danger-500': credits <= 0,
      '!vp-text-warning-500': (credits <= 10) & (credits > 0),
    }"
  >
    <div class="vp-text-center">
      Credits
      <div v-if="credits" class="vp-leading-3">
        {{ credits }}
      </div>
    </div>
  </VyButton>
</template>

<script>
import { Credit } from "icons/icons.js";
import { mapGetters } from "vuex";
import query from "./get-credits.gql";
const { VITE_WEB_URL } = import.meta.env;

export default {
  VITE_WEB_URL,
  icons: {
    Credit,
  },
  data() {
    return {
      credits: null,
    };
  },
  props: {
    collapsed: Boolean,
    product: String,
    exact: Boolean,
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
    }),
  },

  apollo: {
    credits() {
      return {
        query: this.$gql(query),
        variables() {
          return {
            id: this.campaignId,
          };
        },
        update(res) {
          if (res.campaign.credits) {
            return new Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Math.abs(res.campaign.credits));
          } else return null;
        },
      };
    },
  },
};
</script>

<style>
.nav__link .button__label {
  margin-left: 0 !important;
  margin-top: 4px;
  font-size: 12px;
}
</style>
