<template>
  <component
    :is="component"
    class="vp-relative vp-rounded-xl vp-border-2 vp-border-warning-100 vp-p-6 vp-flex hover:vp-border-warning-300 vp-cursor-pointer"
    v-bind="$attrs"
  >
    <img
      class="vp-mt-2 vp-w-12 vp-h-12 vp-flex-grow-0"
      :src="icon"
      :alt="title"
    />
    <div class="vp-ml-3">
      <h4 class="vp-text-warning-500 vp-text-lg vp-font-bold">{{ title }}</h4>
      <p class="vp-text-gray-500 vp-mt-1">{{ desc }}</p>
    </div>

    <!-- DOT -->
    <div
      class="vp-absolute -vp-right-1.5 -vp-top-1.5 vp-w-4 vp-h-4 vp-rounded-full vp-bg-warning-500"
    ></div>
  </component>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    icon: {},
  },

  computed: {
    component() {
      if (this.$attrs.href) return "a";
      if (this.$attrs.to) return "router-link";
      return "div";
    },
  },
};
</script>
