<template>
  <VpField label="Status">
    <VpInput>
      <VpSelect
        @input="$emit('input', $event)"
        :value="value"
        :options="options"
        placeholder="Select"
      />
    </VpInput>
  </VpField>
</template>

<script>
export default {
  props: {
    value: String,
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
