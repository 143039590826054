<template>
  <div>
    <slot v-if="pending" name="pending"></slot>
    <slot v-else-if="error" name="error" :error="error">
      <div class="vp-bg-danger-50 vp-rounded-md vp-p-4 vp-text-danger-500">
        <p class="vp-text-xs">{{ error }}</p>
      </div>
    </slot>
    <slot v-else :res="res"></slot>
  </div>
</template>

<script>
export default {
  props: {
    req: {},
  },
  data() {
    return {
      error: null,
      res: null,
      pending: true,
    };
  },
  async created() {
    this.pending = true;
    this.req()
      .then((res) => {
        this.res = res;
      })
      .catch((err) => {
        this.error = err;
      })
      .finally(() => {
        this.pending = false;
      });
  },
};
</script>
