<template>
  <VpLoaderContainer loading v-if="loading" class="vp-py-12">
  </VpLoaderContainer>
  <div v-else>
    <div v-if="errors" class="vp-p-4 vp-mb-4">
      <ApiError :data="errors" />
    </div>
    <slot v-else :res="res" :refresh="refresh" />
  </div>
</template>

<script>
import ApiError from "./api-error.vue";

export default {
  props: {
    get: Function,
    params: Object,
    breadcrumb: [Boolean, String],
    breadcrumbRoute: String,
    cache: String,
  },

  components: {
    ApiError,
  },

  data() {
    return {
      errors: null,
      loading: false,
      res: null,
    };
  },
  created() {
    this.refresh(true);
  },

  methods: {
    async refresh(isInitial = false) {
      if (!this.get) return;

      if (!isInitial && this.cache) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: this.cache,
        });
      }

      this.loading = true;
      this.errors = null;
      try {
        this.res = await this.get();
        if (this.breadcrumb) {
          this.$root.$emit(
            "breadcrumbs-label",
            this.breadcrumbRoute || this.$route.name,
            this.res?.[this.breadcrumb]
          );
        }
      } catch (err) {
        console.error(err);
        this.errors = err;
      }
      this.loading = false;
    },
  },
};
</script>
