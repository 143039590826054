<template>
  <VpListLayout class="vp-h-full" :aside="true">
    <template #left>
      <VpSubNav class="vp-p-4">
        <VpSubNavItem
          :to="{ name: 'contacts-bulk-history' }"
          label="History"
          align="left"
          fluid
        />
        <VpSubNavItem
          :to="{ name: 'contacts-bulk-import' }"
          label="Bulk Import"
          align="left"
          fluid
        />
        <VpSubNavItem
          :to="{ name: 'contacts-bulk-update' }"
          label="Bulk Update"
          align="left"
          fluid
        />
      </VpSubNav>
    </template>

    <router-view />
  </VpListLayout>
</template>

<script>
export default {
  props: {
    storeId: [Number, String],
  },
};
</script>
