import Vue from "vue";

import VueList from "@7span/vue-list";
import { getCampaign } from "./utils.js";

const app = new Vue();
// import { apolloClient } from "plugins/apollo";

function stateManagerKey(endpoint, version) {
  const campaignId = getCampaign();
  return `vue-list--${campaignId}--${endpoint}--${version}`;
}

export const init = () => {
  Vue.use(VueList, {
    stateManager: {
      init(endpoint, state) {
        const campaignId = getCampaign();
        const allKeys = `vue-list--${campaignId}--${endpoint}`;
        const latestKey = stateManagerKey(endpoint, state.version);
        const staleKeys = Object.keys(localStorage).filter(
          (key) => key.startsWith(allKeys) && key !== latestKey
        );
        staleKeys.forEach((key) => localStorage.removeItem(key));
      },
      get(endpoint, state) {
        const key = stateManagerKey(endpoint, state.version);

        if (state.requestPayload && state.requestPayload.noFilterStorage)
          return null;

        try {
          if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
          } else {
            return null;
          }
        } catch {
          return null;
        }
      },
      set(endpoint, state) {
        if (state.requestPayload && state.requestPayload.noFilterStorage)
          return;
        const _state = {
          attrSettings: state.attrSettings,
          filters: state.filters,
          page: state.page,
          perPage: state.perPage,
          pagination: state.pagination,
          sort: state.sort,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          version: state.version,
        };
        const key = stateManagerKey(endpoint, _state.version);
        localStorage.setItem(key, JSON.stringify(_state));
      },
    },
    requestHandler(data) {
      const { filters, params, search, pagination, sort, requestPayload } =
        data;

      let staticFilters = null;

      if (requestPayload && requestPayload.staticFilters) {
        staticFilters = requestPayload.staticFilters;
      }

      let staticParams = null;

      if (requestPayload && requestPayload.staticParams) {
        staticParams = requestPayload.staticParams;
      }

      const vars = {
        filters: {
          ...filters,
          ...staticFilters,
        },
        search,
        perPage: pagination.perPage,
        page: pagination.page ? Number(pagination.page) : 1,
        ...params,
        ...staticParams,
      };

      if (sort && sort.by) {
        vars.sort = sort;
      }

      //TODO: Remove cache parameter once the cache is cleared
      // if (!useCache) {
      //   apolloClient.cache.evict({
      //     id: "ROOT_QUERY",
      //     fieldName: gqlQuery,
      //   });
      // }

      // params.canRead is for to avoid calling api when user have no permissions.

      const queryConfig = {
        noCache: false,
      };

      if (requestPayload.noCache === true) queryConfig.noCache = true;

      return app.$query(requestPayload.query, vars, queryConfig).then((res) => {
        //The collection has only key
        //So we can get it from first index.
        const keys = Object.keys(res.data);
        const queryRes = res.data[keys[0]];

        return {
          items: queryRes.data,
          count: queryRes.pagination.total,
          //Original Response, usefull if we want to display additional data send via API
          //GraphQL can request additional data so can make use of them.
          res: res,
        };
      });
    },
  });
};
