<template>
  <div class="vp-flex vp-flex-nowrap">
    <VpVueForm
      :fields="fields"
      :id="id"
      class="vp-p-6"
      :save="save"
      :get="get"
      :del="del"
      :archive="del"
      width="400px"
      :form-state.sync="formState"
      cache="quickReply"
      collection-cache="quickReplies"
      ref="quickReply"
      :track="$options.track"
      track-prefix="Quick Reply"
      :feature="feature.key"
    >
      <template #default="{ state, response }">
        <!-- Remove this alerts -->
        <VpShouldDisplayOnStoreAlert
          v-if="response && !response.shouldDisplayOnStore"
          title="Quick Reply not accessible!"
          desc="This quick reply is not accessible on extension."
        />
        <VpField rules="required" name="Name" label="Name">
          <VpInput>
            <VpTextBox v-model="state.name" />
          </VpInput>
        </VpField>
        <VpField
          rules="required"
          name="Message To Send"
          label="Message To Send"
        >
          <VpTextArea v-model="state.message" :rows="15" />
        </VpField>

        <VpField
          inline
          label="Pin in Extension"
          note="In our Chrome Extension, you can pin this item in quick-reply bar to quickly send this poll as a message."
        >
          <VpSwitch v-model="state.isPinned" />
        </VpField>
      </template>
    </VpVueForm>

    <div class="vp-h-full vp-py-3 vp-flex-auto">
      <VpHeaderActions>
        <QuickReplyInfoModal />
        <VpPortalButton
          :icon="$options.icons.Help"
          label="How to Format"
          @click.native="openModal"
        />
        <VpFile
          v-if="id !== '+' && checkPermission(feature.key, 'upsert')"
          label="Add New File"
          :icon="$options.icons.Add"
          :loading="loading"
          @input="upload"
          :accept="$options.fileTypes"
        />
      </VpHeaderActions>

      <div v-if="id !== '+'">
        <VpList
          :per-page="10"
          :columns="columns"
          cache="quickReplyMedias"
          endpoint="quickReplyMedias"
          :params="params"
          :reorder="true"
          :request-payload="{ query: $options.quickReplyMedias }"
          @reorder="
            reorder('quickReplyMedias', $event, {
              quickReplyId: id,
            })
          "
          class="vp-h-full"
          ref="list"
          title="Files to Send"
          :feature="feature.key"
          :search="false"
        >
          <template #empty>
            <VpErrorPage
              title="No Files Found!"
              desc="Add files to send as attachment with the message. You can upload Images, Videos, PDFs and WhatsApp supported documents."
              label="Upload Now"
            >
              <template #actions>
                <VpFile
                  label="Upload Now"
                  :loading="loading"
                  @input="upload"
                  :accept="$options.fileTypes"
                />
              </template>
            </VpErrorPage>
          </template>

          <template #media="{ item }">
            <VpMedia :value="item.media" />
          </template>

          <template #actions="{ item }">
            <VyButton
              :icon="$options.icons.Delete"
              @click.native="
                checkPermission(feature.key, 'delete', () => {
                  deleteImage(item);
                })
              "
              class="button--danger button--muted button--square button--sm button--rounded"
            />
          </template>
        </VpList>
      </div>
    </div>
  </div>
</template>

<script>
import { Add, Delete, Document, Help } from "icons/icons.js";
import mime from "mime";
import { deleteMedia, uploadMediaToS3 } from "utils/presets";
import { mapGetters } from "vuex";

import QuickReplyInfoModal from "./_quick-reply-info-modal.vue";
import deleteQuickReply from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";
import reorder from "mixins/reorder.js";
import quickReplyMedias from "@/views/crm/quick-replies/getMedias.gql";
import { clearExtCache } from "@/utils";

export default {
  quickReplyMedias,
  icons: {
    Help,
    Add,
    Document,
    Delete,
  },
  // According to https://developers.facebook.com/docs/whatsapp/api/media/
  fileTypes:
    ".jpg,.jpeg,.png,.pdf,.3gp,.mp4,.mp3,.ogg,.aac,.csv,.doc,.docx,.ppt,.xls,.xlsx,.rtf,.txt,.zip",

  track: {
    id: "Id",
    name: "Name",
    message: "Message",
    isPinned: "Pin in Extension",
  },

  props: {
    id: [Number, String],
    feature: Object,
  },

  components: {
    QuickReplyInfoModal,
  },

  mixins: [reorder],

  data() {
    return {
      loading: false,
      formState: {},
      fields: ["name", "message", { name: "isPinned", value: false }],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    params() {
      return {
        quickReplyId: this.id,
      };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        {
          name: "media",
          label: "Preview",
          type: "string",
        },
        {
          name: "mimetype",
          label: "File Type",
          value: (row) => {
            if (mime.getExtension(row.media.mimetype)) {
              return mime.getExtension(row.media.mimetype).toUpperCase();
            } else {
              const questionSplit = row.media.url.split("?");
              const dotSplit = questionSplit[0].split(".");
              return dotSplit[dotSplit.length - 1].toUpperCase();
            }
          },
          type: "string",
        },
        { name: "actions", label: "", type: "string", fix: true },
      ];
    },
  },

  methods: {
    openModal() {
      this.$vayu.modal.open("dynamic-quick-replies");
    },

    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.quickReply,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: data,
      }).then(({ data: { upsertQuickReply } }) => {
        clearExtCache({
          key: this.feature.key,
        });
        this.$router.push({
          name: "quick-reply",
          params: {
            id: upsertQuickReply.id,
          },
        });
      });
    },

    del(id, action) {
      return this.$mutate(deleteQuickReply, {
        id: parseInt(id),
        action,
      }).then(() => {
        clearExtCache({
          key: this.feature.key,
        });
        this.$router.push({ name: "quick-replies" });
      });
    },

    async upload(e) {
      this.loading = true;

      try {
        const { id } = await uploadMediaToS3({
          path: "reply",
          file: e[0],
        });

        this.save(this.id, {
          name: this.formState.name,
          message: this.formState.message,
          mediaIds: [id],
        }).then(() => {
          this.$refs.list.refresh();
        });
      } catch ({ message }) {
        this.$vayu.notify({
          title: "Failed to Add New File",
          message: message,
          state: "danger",
          duration: 6000,
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteImage(data) {
      this.$vayu.confirm({
        name: "delete",
        title: "Delete File",
        message: "Are you sure you want to delete this file?",

        ok: async ({ close }) => {
          close();
          this.loading = true;
          if (data && data.media) {
            await deleteMedia([data.media.id]);
            this.$refs.list.refresh();
          }
          this.loading = false;
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
  },
};
</script>
