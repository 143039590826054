<template>
  <VyButton
    :label="attrs.label"
    class="button--sm button--muted button--pill"
    :class="attrs.color"
  />
</template>
<script>
const values = {
  success: {
    buttonclass: "button--success",
    label: "Success",
  },
  processing: {
    buttonclass: "button--warning",
    label: "Processing",
  },
  failed: {
    buttonclass: "button--danger",
    label: "Failed",
  },
  enqueued: {
    buttonclass: "button--info",
    label: "In Queue",
  },
};
export default {
  props: {
    value: String,
  },
  data() {
    return {
      values,
    };
  },
  computed: {
    attrs() {
      const state = values[this.value];
      if (state) {
        return {
          icon: state.icon,
          color: state.buttonclass,
          label: state.label,
        };
      } else {
        return {
          icon: "",
          color: "button--gray",
          label: "Unknown",
        };
      }
    },
  },
};
</script>
