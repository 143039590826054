const state = {
  id: null,
  name: null,
  url: null,
  country: null,
  autoSyncChat: null,
  vepaarProducts: {
    crm: null,
    stores: [],
  },
};

const getters = {
  isStoreProductSetup(state) {
    return state.vepaarProducts.stores.length ? true : false;
  },

  isCrmProductSetup(state) {
    return state.vepaarProducts.crm ? true : false;
  },

  id(state) {
    return state.id;
  },

  name(state) {
    return state.name;
  },

  url(state) {
    return state.url;
  },

  autoSyncChat(state) {
    return state.autoSyncChat === "active";
  },

  callingCode(state) {
    return state.country?.callingCode;
  },
};

const mutations = {
  set(state, data) {
    const { biz } = data;
    const { id, name, subdomainUrl, country, autoSyncChat, vepaarProducts } =
      biz;

    state.id = id;
    state.name = name;
    state.url = subdomainUrl;
    state.country = country;
    state.autoSyncChat = autoSyncChat;
    state.vepaarProducts = vepaarProducts;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
