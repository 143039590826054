import { SplitFactory } from "@splitsoftware/splitio";
let client;
let isClientReady = false;
const queue = [];

const init = (userId) => {
  const factory = SplitFactory({
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_AUTH_KEY,
      key: userId,
    },
  });
  client = factory.client();
  client.on(client.Event.SDK_READY, function () {
    isClientReady = true;
    queue.forEach(({ treatmentName, callback }) => {
      treatment(treatmentName, callback);
    });
    queue.length = 0;
  });
};

const treatment = (treatmentName, callback) => {
  if (isClientReady) {
    const treatment = client.getTreatment(treatmentName);
    const res = treatment === "on" ? true : false;
    callback(res);
  } else {
    queue.push({ treatmentName, callback });
  }
};

export { init, client, treatment };
