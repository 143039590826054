<template>
  <VpListLayout>
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="processExcelHistory"
      :request-payload="{ query: $options.processExcelHistory }"
      :params="params"
      cache="processExcelHistory"
      class="vp-h-full"
      ref="list"
      title="Bulk Actions History"
      :search="false"
      :feature="feature"
    >
      <template #status="{ item }">
        <status :value="item.status" />
      </template>
      <template #action="{ item }">
        <ActionName :value="item.action" />
      </template>
      <template #createdAt="{ item }">
        <VpDatetime
          :value="item.createdAt"
          class="vp-text-xs vp-text-gray-500"
        />
      </template>
      <template #download="{ item }">
        <VyButton
          :label="item.mediaName"
          :href="item.url"
          target="_blank"
          class="button--gray button--sm"
        />
      </template>
      <template #log="{ item }">
        <VyButton
          v-if="item.result"
          label="View Log"
          @click.native="
            modalLog = item.result;
            modalStatus = item.status;
            $vayu.modal.open('excel-log');
          "
          class="button--primary button--sm button--muted button--rounded"
        />
      </template>
    </VpList>
    <log :data="modalLog" :status="modalStatus" />
  </VpListLayout>
</template>

<script>
import Status from "./status.vue";
import ActionName from "./action-name.vue";
import Log from "./log.vue";
import processExcelHistory from "graph/excel/history.gql";

export default {
  processExcelHistory,
  props: {
    module: String,
    feature: String,
  },
  components: {
    Status,
    ActionName,
    Log,
  },
  data() {
    return {
      modalLog: null,
      modalStatus: null,
    };
  },
  computed: {
    params() {
      return { module: this.module };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "action", type: "string" },
        { name: "status", type: "string" },
        { name: "download", type: "string" },
        { name: "log", type: "string" },
        { name: "createdAt", type: "string" },
      ];
    },
  },
};
</script>
