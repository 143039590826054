<template>
  <div class="vp-font-semibold">
    <p v-if="isOutOfStock || value === 0" class="vp-text-danger-500">0</p>
    <p v-else-if="value == null" class="vp-text-gray-400">N/A</p>
    <p v-else>{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    isOutOfStock: Boolean,
    value: Number,
  },
};
</script>
