<template>
  <div>
    <section class="vp-grid vp-grid-cols-2 vp-gap-2">
      <div v-for="(item, key) in cards" :key="`incoming-${key}`">
        <div class="vp-flex vp-items-center vp-p-3 vp-rounded-md vp-border">
          <div
            class="vp-w-12 vp-h-12 vp-flex vp-items-center vp-justify-center vp-rounded-full vp-text-2xl vp-mr-4 vp-flex-grow-0 vp-bg-primary-50"
          >
            <VyIcon class="vp-text-primary-500" :name="item.icon" />
          </div>

          <div>
            <h3 class="vp-leading-none vp-text-2xl vp-font-bold">
              {{ item.count }}
            </h3>
            <p class="vp-leading-none vp-mt-1">
              {{ item.label }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <p class="vp-text-gray-400 vp-mt-2">
      * Messages which are sent by user and saved in Vepaar
    </p>
  </div>
</template>

<script>
import { Message, Image, Video, Audio } from "icons/icons.js";
export default {
  icons: {
    Message,
    Image,
    Video,
    Audio,
  },
  props: {
    request: Function,
  },
  created() {
    this.get();
  },
  data() {
    return {
      loading: false,
      error: false,
      statistics: null,
      cards: {
        text: {
          icon: this.$options.icons.Message,
          color: "success",
          textColor: "vp-text-success-700",
          cardColor: "vp-bg-success-50",
          iconColor: "vp-bg-success-100",
          count: "***",
          label: "Messages",
        },
        image: {
          icon: this.$options.icons.Image,
          color: "info",
          textColor: "vp-text-info-700",
          cardColor: "vp-bg-info-50",
          iconColor: "vp-bg-info-100",
          label: "Images",
          count: "***",
        },
        video: {
          icon: this.$options.icons.Video,
          color: "danger",
          textColor: "vp-text-danger-700",
          cardColor: "vp-bg-danger-50",
          iconColor: "vp-bg-danger-100",
          label: "Videos",
          count: "***",
        },
        audio: {
          icon: this.$options.icons.Audio,
          color: "warning",
          textColor: "vp-text-warning-700",
          cardColor: "vp-bg-warning-50",
          iconColor: "vp-bg-warning-100",
          label: "Audio",
          count: "***",
        },
      },
    };
  },
  methods: {
    async get() {
      this.loading = true;
      try {
        // TODO: This request is failing
        const { text, image, video, audio } = await this.request();
        this.cards.text.count = text;
        this.cards.image.count = image;
        this.cards.video.count = video;
        this.cards.audio.count = audio;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>
