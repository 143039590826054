<template>
  <div class="vp-relative">
    <slot name="trigger" :toggle="toggle">
      <VyButton
        type="button"
        :label="label"
        class="button--gray vp-w-full button--muted button--right"
        :icon="icon"
        @click.native="toggle()"
      />
    </slot>
    <div
      class="vp-absolute vp-left-0 vp-right-0 vp-z-50"
      :class="{
        'vp-right-auto': align == 'left',
        'vp-left-auto': align == 'right',
      }"
      v-if="open || show"
      v-click-outside="close"
    >
      <slot name="container" :toggle="toggle">
        <div
          class="vp-border vp-border-gray-100 vp-bg-white vp-rounded-md vp-mt-1 vp-overflow-auto vp-drop-shadow-xl"
          :style="{ maxHeight }"
        >
          <slot :toggle="toggle"></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { ChevronDown } from "icons/icons.js";

export default {
  props: {
    label: {
      type: String,
      default: "Select",
    },
    icon: {
      default: () => ChevronDown,
    },
    maxHeight: {
      type: String,
      default: "300px",
    },
    show: Boolean,
    align: {
      type: String,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    toggle() {
      this.open = !this.open;
    },
    close() {
      this.open = false;
      this.$emit("update:show", false);
    },
  },
};
</script>
