<template>
  <div
    class="vp-bg-gray-1000 vp-flex vp-flex-col vp-overflow-auto vp-h-full vp-items-center vp-gap-4"
  >
    <!-- Logo -->
    <a :href="$options.VITE_WEB_URL" target="_self">
      <img src="/img/icon.png" class="vp-w-9 vp-h-9 vp-mt-4" />
    </a>

    <!-- Nav -->
    <div class="vp-flex vp-flex-col vp-flex-grow vp-w-full">
      <!-- TOP -->
      <div class="vp-flex vp-flex-col vp-items-center vp-gap-2">
        <VyButton
          :href="$options.VITE_CRM_URL"
          :class="{
            'vp-text-white vp-border-l-[4px] vp-border-primary-500':
              product === 'crm',
          }"
          class="vp-h-14 nav__link vp-w-full button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          :icon="$options.icons.Crm"
          label="CRM"
        />
        <VyButton
          :href="$options.VITE_STORE_URL"
          :class="{
            'vp-text-white vp-border-l-[4px] vp-border-primary-500':
              product === 'store',
          }"
          class="vp-h-14 vp-w-full nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          :icon="$options.icons.Store"
          label="Store"
        />

        <VyButton
          :href="$options.VITE_CATALOG_URL"
          :class="{
            'vp-text-white vp-border-l-[4px] vp-border-primary-500':
              product === 'catalog',
          }"
          class="vp-h-14 vp-w-full nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          :icon="$options.icons.Catalog"
          label="Catalog"
        />
      </div>

      <!-- BOTTOM -->
      <div class="vp-mt-auto vp-flex vp-flex-col vp-items-center vp-gap-2">
        <RouterLink
          class="vp-cursor-pointer"
          v-if="product === 'web'"
          :to="{ name: 'help' }"
          v-slot="{ isActive }"
        >
          <VyButton
            label="Help"
            :icon="$options.icons.Help"
            :to="{ name: 'help' }"
            :class="{
              'vp-text-white vp-border-l-[4px] vp-border-primary-500': isActive,
            }"
            class="vp-shrink-0 vp-h-14 vp-w-full nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          />
        </RouterLink>
        <VyButton
          v-else
          :href="`${$options.VITE_WEB_URL}/redirect?to=help`"
          class="vp-w-full vp-h-14 vp-shrink-0 nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          :icon="$options.icons.Help"
          label="Help"
        />

        <Credits class="vp-shrink-0 vp-w-full vp-h-14" :product="product" />

        <RouterLink
          class="vp-cursor-pointer"
          v-if="product === 'web'"
          :to="{ name: 'account' }"
          v-slot="{ isActive }"
        >
          <VyButton
            label="Account"
            :icon="$options.icons.Contact"
            :to="{ name: 'account' }"
            :class="{
              'vp-text-white vp-border-l-[4px] vp-border-primary-500': isActive,
            }"
            class="vp-w-full vp-h-14 nav__link button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          />
        </RouterLink>
        <VyButton
          v-else
          :href="`${$options.VITE_WEB_URL}/redirect?to=account/my-profile`"
          class="nav__link vp-h-14 vp-w-full vp-shrink-0 button--md button--center vp-text-gray-500 hover:vp-text-white vp-flex-col vp-px-0"
          :icon="$options.icons.Contact"
          label="Account"
        />
      </div>
    </div>

    <p
      class="vp-w-full vp-bg-black vp-bg-opacity-50 vp-text-xs vp-text-center vp-py-1 vp-text-gray-700"
    >
      <slot name="version"> </slot>
    </p>
  </div>
</template>

<script>
import { Contact, Crm, Store, Help, Catalog } from "icons/icons.js";

import Credits from "./Credits.vue";
const { VITE_CRM_URL, VITE_STORE_URL, VITE_WEB_URL, VITE_CATALOG_URL } =
  import.meta.env;
export default {
  VITE_CRM_URL,
  VITE_STORE_URL,
  VITE_WEB_URL,
  VITE_CATALOG_URL,
  icons: {
    Crm,
    Store,
    Contact,
    Help,
    Catalog,
  },

  components: {
    Credits,
  },
  props: {
    product: String,
  },
};
</script>

<style scoped>
.nav__link .button__label {
  margin-left: 0 !important;
  margin-top: 4px;
  font-size: 12px;
}
</style>
