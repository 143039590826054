<template>
  <div>
    <div
      v-if="!fromExtension"
      class="vp-flex vp-flex-nowrap vp-items-center vp-bg-warning-100 vp-p-6 vp-rounded-md vp-space-x-4"
    >
      <Icon
        :name="$options.vectors.LockedFeature"
        class="vp-w-24 vp-h-24"
        alt=""
      />
      <div class="vp-flex-auto">
        <h4 class="vp-text-warning-500 vp-text-lg vp-font-bold">
          It's time to upgrade!
        </h4>
        <p class="vp-text-warning-500">
          Feature is unavailable in current plan. Upgrade to unlock.
        </p>
      </div>
      <div class="vp-ml-auto">
        <VyButton
          :icon="$options.icons.Upgrade"
          @click.native="goTo"
          label="Upgrade"
          class="button--warning button--solid button--md button--rounded"
        />
      </div>
    </div>

    <div v-else class="vp-items-center vp-bg-warning-100 vp-p-6 vp-rounded-md">
      <Icon :name="$options.vectors.LockedFeature" class="vp-w-10 vp-h-10" />
      <!-- TODO: Remove this component and use VyIcon -->
      <h4 class="vp-text-warning-500 vp-mt-2 vp-text-lg vp-font-bold">
        It's time to upgrade!
      </h4>
      <p class="vp-text-warning-500 vp-mt-2">
        Feature is unavailable in current plan. Upgrade to unlock.
      </p>
      <VyButton
        :icon="$options.icons.Upgrade"
        @click.native="goTo"
        label="Upgrade"
        class="button--warning button--solid button--md button--rounded vp-ml-auto vp-mt-2"
      />
    </div>
  </div>
</template>

<script>
import { Upgrade } from "icons/icons.js";
import { LockedFeature } from "icons/vectors.js";
import Icon from "./icon.vue";
import { toSubscription } from "plugins/utils.js";

export default {
  components: {
    Icon,
  },
  vectors: {
    LockedFeature,
  },
  icons: {
    Upgrade,
  },
  props: {
    feature: String,
    //TODO: Remove this prop
    fromExtension: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    goTo() {
      this.$vayu.modal.close("pro-feature-modal");
      //TODO: Remove this check
      if (this.PLATFORM == "WEB") {
        toSubscription();
      } else {
        window.open(this.$toWeb("subscription-all"), "_blank");
      }

      this.$track("Upgrade Button Clicked", {
        Source: "Popup",
        Feature: this.feature,
      });
    },
  },
};
</script>
