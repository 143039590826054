<template>
  <div
    class="vp-rounded-xl vp-p-6 vp-flex vp-flex-col vp-w-full vp-gap-1"
    :class="$options.colorClass[color]"
    :style="{ width: width || false }"
  >
    <h2 class="vp-font-bold vp-text-4xl">{{ emoji }}</h2>
    <component
      class="vp-font-bold"
      :is="titleTag"
      v-if="title"
      :class="$options.fontColorClass[color]"
    >
      {{ title }}
    </component>
    <div
      v-if="$scopedSlots.default"
      class="vp-whitespace-pre-line vp-flex-auto vp-flex vp-flex-col vp-gap-1 vp-mt-2"
    >
      <slot></slot>
    </div>
    <div class="vp-flex vp-items-center">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
const colorClass = {
  info: "vp-bg-info-100",
  success: "vp-bg-success-100",
  warning: "vp-bg-warning-100",
  danger: "vp-bg-danger-100",
};
const fontColorClass = {
  info: "vp-text-info-500",
  success: "vp-text-success-500",
  warning: "vp-text-warning-500",
  danger: "vp-text-danger-500",
};

export default {
  colorClass,
  fontColorClass,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    title: String,
    emoji: {
      type: String,
      default: "👋",
    },
    width: String,
    titleTag: {
      type: String,
      default: "h4",
    },
  },
};
</script>
