<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    provide: {},
  },
  provide() {
    return this.provide;
  },
};
</script>
