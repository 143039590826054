<template>
  <div
    v-if="video"
    class="vp-aspect-w-16 vp-aspect-h-9 vp-rounded-lg vp-overflow-hidden vp-bg-black"
  >
    <iframe :src="video" allowfullscreen allowtransparency allow="autoplay" />
  </div>
</template>

<script>
import validate from "utils/url-parser";

export default {
  props: {
    value: String,
  },

  watch: {
    video(newValue) {
      this.$emit("change", newValue);
    },
  },

  computed: {
    video() {
      if (this.value) {
        return validate(this.value);
      }
      return null;
    },
  },
};
</script>
