import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { cloneDeep } from "lodash-es";
import { getToken, getCampaign, getStore } from "./utils";
import graphqlTag from "graphql-tag";

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL,
});

const httpPublicLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_OPEN,
});

const uploadLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them

  const _headers = {
    ...headers,
    Authorization: getToken(),
    CampaignId: getCampaign(),
  };

  const storeId = getStore();

  if (storeId) _headers.storeId = storeId;

  return {
    headers: _headers,
  };
});

const defaultQueryConfig = {
  fetchPolicy: "cache-first",
};

const queryConfigGenerator = (options = {}) => {
  let queryConfig = {};
  if (options.noCache === true) queryConfig.fetchPolicy = "network-only";
  const config = { ...defaultQueryConfig, ...queryConfig };
  return config;
};

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const apolloPublicClient = new ApolloClient({
  link: authLink.concat(httpPublicLink),
  cache: new InMemoryCache(),
});

export const apolloUpload = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

export const gql = (query) =>
  graphqlTag`
    ${query}
  `;

export const queryPublic = (query, variables) =>
  apolloPublicClient
    .query({
      query: gql(query),
      variables,
    })
    .then((res) => cloneDeep(res));

export const mutatePublic = (mutation, variables) =>
  apolloPublicClient
    .mutate({
      mutation: gql(mutation),
      variables,
    })
    .then((res) => cloneDeep(res));

export const query = (query, variables, config) =>
  apolloClient
    .query({
      query: gql(query),
      variables,
      ...queryConfigGenerator(config),
    })
    .then((res) => cloneDeep(res));

export const mutate = (mutation, variables) =>
  apolloClient
    .mutate({
      mutation: gql(mutation),
      variables,
    })
    .then((res) => cloneDeep(res));

export const upload = (mutation, variables) =>
  apolloUpload
    .mutate({
      mutation: gql(mutation),
      variables,
    })
    .then((res) => cloneDeep(res));
