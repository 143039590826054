<template>
  <VyButton
    v-bind="$attrs"
    class="button--primary button--sm button--rounded button--muted"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
