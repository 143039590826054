<template>
  <div
    class="vp-flex vp-justify-center vp-items-center vp-flex-col vp-h-full vp-mt-12"
  >
    <slot name="media">
      <!-- Media -->
      <div v-if="feature.video" class="vp-w-1/2">
        <EmbededVideo :value="feature.video" />
      </div>

      <div v-else-if="feature.image" class="vp-w-1/2">
        <div class="vp-aspect-w-16 vp-aspect-h-9 vp-rounded vp-overflow-hidden">
          <img
            :src="feature.image"
            alt=""
            class="w-full h-full vp-object-contain"
          />
        </div>
      </div>
      <div v-else>
        <div class="vp-bg-primary-100 vp-p-2 vp-rounded-full vp-inline-block">
          <div class="vp-bg-primary-300 vp-p-2 vp-rounded-full vp-inline-block">
            <div
              class="vp-bg-primary-500 vp-w-20 vp-h-20 vp-rounded-full vp-flex vp-justify-center vp-items-center vp-text-white"
            >
              <VyIcon
                class="vp-text-white vp-w-12 vp-h-12"
                :name="$options.icons.Alert"
              />
            </div>
          </div>
        </div>
      </div>
    </slot>

    <!-- Title -->
    <h3 class="vp-text-2xl vp-font-bold vp-mt-3">
      {{ feature.title ? `${feature.title} Not Found!` : "No Data Found!" }}
    </h3>

    <!-- Description -->
    <p class="vp-text-gray-700 vp-mb-4 vp-mt-2 vp-w-1/2 vp-text-center">
      {{ feature.desc || "Try changing filters." }}
    </p>

    <!-- Actions -->
    <slot name="action">
      <AddItem
        v-if="route"
        :feature="feature"
        :route="route"
        size="md"
        @click="$emit('click')"
      />
      <VyButton
        v-if="feature.documentation"
        class="vp-mt-1 button--primary button--md"
        label="Know More"
        :href="feature.documentation"
        target="_blank"
      />
    </slot>
  </div>
</template>

<script>
import EmbededVideo from "../embeded-video.vue";
import AddItem from "./add-item.vue";
import { Alert } from "icons/icons.js";
export default {
  icons: {
    Alert,
  },
  components: {
    EmbededVideo,
    AddItem,
  },
  props: {
    feature: Object,
    route: String,
  },
};
</script>
