<template>
  <div>
    <!-- Need wrapper as tailwind space class overrides the margin -->
    <div class="vp-pb-1 vp-border-b" :class="{ 'vp-mt-6': !isFirst }">
      <p class="vp-text-xs vp-font-semibold vp-text-gray-500"><slot /></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFirst: Boolean,
  },
};
</script>
