<template>
  <RouterLink :to="to" v-slot="{ href, navigate, isActive, isExactActive }">
    <VyButton
      :href="href"
      @click="navigate"
      v-bind="$attrs"
      class="button--md button--rounded button--left vp-w-full"
      :class="{
        'vp-bg-primary-50 vp-text-primary-500': exact
          ? isExactActive
          : isActive,
        'vp-text-gray-500 hover:vp-bg-gray-200 hover:vp-text-gray-700':
          !isActive || !isExactActive,
      }"
    />
  </RouterLink>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    exact: Boolean,
    to: [Object, String],
  },
};
</script>
