import chat from "graph/chatExport.gql";
import contact from "graph/contactsExport.gql";
import whatsAppGroup from "graph/whatsAppGroupsExport.gql";
import whatsAppGroupMember from "graph/whatsAppGroupMemberExport.gql";
import poll from "graph/pollVotersExport.gql";
import order from "graph/ordersExport.gql";
import ticket from "graph/ticketExport.gql";

export default {
  chat,
  contact,
  whatsAppGroup,
  whatsAppGroupMember,
  poll,
  order,
  ticket,
};
