<template>
  <VyInput class="input--rounded">
    <VyTextarea
      :value="value"
      v-bind="$attrs"
      @input="$emit('input', $event)"
    />
  </VyInput>
</template>
<script>
export default {
  props: {
    value: [String, Number],
  },
  inheritAttrs: false,
};
</script>
