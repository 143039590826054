<template>
  <div
    class="vp-p-6 vp-bg-white vp-flex icon-card--inline"
    :class="{
      'vp-rounded vp-cursor-pointer vp-border': clickable,
      'vp-border-primary-200 vp-ring-2 vp-ring-inset vp-ring-primary-200':
        itemKey === value,
    }"
    @click="clickable && $emit('input', itemKey)"
  >
    <!-- ICON -->
    <div
      class="vp-bg-primary-50 vp-text-primary-500 vp-rounded-full vp-h-12 vp-w-12 vp-flex vp-justify-center vp-items-center vp-flex-none vp-mr-3"
      v-if="icon"
    >
      <img :src="icon" class="vp-h-8 vp-w-8" v-if="iconType === 'link'" />
      <VpIcon v-else class="vp-h-8 vp-w-8" :name="icon" />
    </div>

    <!-- DESC -->
    <div class="vp-flex vp-flex-col vp-self-center">
      <label
        class="vp-text-gray-500"
        :class="{ 'vp-cursor-pointer': clickable }"
      >
        <div class="vp-flex vp-gap-1 vp-items-center">
          {{ desc }}

          <VyIcon
            v-tooltip="tooltip"
            v-if="tooltip"
            :name="$options.icons.InfoOutline"
            class="vp-text-gray-500"
          />
        </div>
      </label>
      <p class="vp-text-lg vp-font-bold vp-leading-none">
        <slot name="title">
          {{ title }}
        </slot>
      </p>
    </div>
  </div>
</template>

<script>
import { InfoOutline } from "icons/icons.js";
export default {
  icons: {
    InfoOutline,
  },
  props: {
    tooltip: null,
    icon: {},
    itemKey: {
      type: [String, Number],
      default: null,
    },
    title: [String, Number],
    desc: String,
    size: {
      type: Number,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    iconType: {
      type: String,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit("input", this.itemId);
      }
    },
  },
};
</script>
