import List from "@/views/crm/marketing/whatsapp-broadcast/List.vue";
import Index from "@/views/crm/marketing/whatsapp-broadcast/Index.vue";
import Item from "@/views/crm/marketing/whatsapp-broadcast/Item.vue";
import Analytics from "@/views/crm/marketing/whatsapp-broadcast/Analytics.vue";
import Contacts from "@/views/crm/marketing/whatsapp-broadcast/Contacts.vue";

export default [
  {
    path: "whatsapp-broadcasts/:via",
    name: "whatsapp-broadcasts",
    component: List,
    meta: {
      label: "Broadcast",
      breadcrumbs: ["home", "whatsapp-broadcasts"],
    },
  },
  {
    // TODO make separate component for extension and web
    path: "whatsapp-broadcasts/:via/:id",
    component: Index,
    props: true,
    children: [
      {
        path: "",
        name: "whatsapp-broadcast",
        component: Item,
        props: true,
        meta: {
          label: "Broadcast",
          breadcrumbs: ["home", "whatsapp-broadcasts", "whatsapp-broadcast"],
        },
      },
      {
        path: "analytics",
        name: "whatsapp-broadcast-analytics",
        component: Analytics,
        props: true,
        meta: {
          label: "Analytics",
          breadcrumbs: [
            "home",

            "whatsapp-broadcasts",
            "whatsapp-broadcast",
            "whatsapp-broadcast-analytics",
          ],
        },
      },
      {
        path: "contacts",
        name: "whatsapp-broadcast-contacts",
        component: Contacts,
        props: true,
        meta: {
          label: "Contacts",
          breadcrumbs: [
            "home",

            "whatsapp-broadcasts",
            "whatsapp-broadcast",
            "whatsapp-broadcast-contacts",
          ],
        },
      },
    ],
  },
];
