<template>
  <div>
    <h2 class="vp-text-3xl vp-font-semibold">{{ title }}</h2>
    <p class="vp-text-gray-500 vp-text-base vp-mt-2 vp-font-medium">
      {{ desc }}
    </p>
    <hr class="vp-mt-7 vp-border" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
};
</script>
