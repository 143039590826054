<template>
  <input
    class="vp-w-full vp-bg-transparent vp-px-2 vp-outline-none"
    :value="value"
    @input="input($event)"
    type="date"
    :min="minDate"
  />
</template>

<script>
export default {
  props: {
    value: String,
    min: Date,
  },

  computed: {
    minDate() {
      if (!this.min) return;

      const year = this.min.getFullYear();
      const month = String(this.min.getMonth() + 1).padStart(2, "0");
      const day = String(this.min.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
