<template>
  <div
    v-if="readonly"
    class="vp-w-6 vp-h-6 vp-rounded-full"
    :class="$options.colorClasses[value]"
  ></div>
  <div v-else class="vp-flex vp-flex-wrap vp-space-x-1">
    <div
      class="vp-w-8 vp-h-8 vp-rounded-full vp-flex vp-items-center vp-justify-center vp-text-white vp-cursor-pointer hover:vp-ring-2 vp-ring-offset-2 vp-ring-gray-200"
      v-for="color in colors"
      :key="color"
      :class="$options.colorClasses[color]"
      @click="$emit('input', color)"
    >
      <VpIcon
        v-if="value == color"
        :name="$options.icons.Check"
        class="vp-w-4 vp-h-4"
      />
    </div>
  </div>
</template>
<script>
import { Check } from "icons/icons.js";
import colorClasses from "static/src/button-color-classes.json?inline";

export default {
  icons: {
    Check,
  },
  colorClasses,
  props: {
    value: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      colors: [
        "grey",
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "cyan",
        "green",
        "lime",
        "amber",
        "orange",
      ],
    };
  },
};
</script>
