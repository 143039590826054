import { CHROME_EXT_ID } from "utils/const";

export const sendMessageToExt = (action, data, callback) => {
  // Some browser runtime is undefined
  window.chrome.runtime?.sendMessage(
    CHROME_EXT_ID,
    {
      action: action,
      data: data,
    },
    (res) => {
      callback(res);
    }
  );
};

export const clearExtCache = (data, callback) => {
  // Some browser runtime is undefined
  window.chrome.runtime?.sendMessage(
    CHROME_EXT_ID,
    {
      action: "CLEAR_EXT_CACHE",
      data: data,
    },
    (res) => {
      callback(res);
    }
  );
};
