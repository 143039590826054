<template>
  <component
    :is="isLink ? 'router-link' : 'div'"
    class="vp-flex vp-items-center"
    :to="isLink ? profileRoute : null"
  >
    <!-- PHOTO -->
    <Avatar
      class="vp-mr-2 vp-flex-none"
      :image="photo"
      :name="name"
      size="sm"
    />

    <!-- DETAILS -->
    <div class="vp-flex-grow vp-overflow-hidden">
      <p
        class="vp-whitespace-nowrap vp-overflow-hidden vp-text-ellipsis"
        :class="{ 'hover:vp-text-primary-500 vp-no-underline': clickable }"
      >
        {{ displayName }}
      </p>
      <p
        class="vp-text-gray-800 vp-text-xs vp-whitespace-nowrap vp-overflow-hidden vp-text-ellipsis"
      >
        {{ displayDesc }}
      </p>
      <slot name="footer"></slot>
    </div>

    <!-- ACTIONS -->
    <div class="vp-flex-none vp-flex vp-items-center">
      <slot name="actions" />
    </div>
  </component>
</template>

<script>
import { startCase } from "lodash-es";
import Avatar from "./avatar.vue";

export default {
  props: {
    data: Object,
    clickable: Boolean,
    type: {
      type: String,
      required: true,
    },
    hasLink: Boolean,
  },

  components: {
    Avatar,
  },

  computed: {
    displayName() {
      return this.name || this.mobileNo;
    },
    displayDesc() {
      if (this.data?.desc) {
        return this.data.desc;
      } else if (this.type == "contact" || this.type == "user") {
        return this.name ? this.mobileNo : "Contact";
      } else {
        const participants = this.data.participantsCount;
        return participants == null
          ? startCase(this.type)
          : participants + " Participants";
      }
    },
    whatsappId() {
      if (this.type == "contact" || this.type == "user") {
        return this.data?.mobileNumber?.numberWithCallingCode;
      } else {
        return this.data.whatsappId;
      }
    },
    mobileNo() {
      return (
        this.data?.mobileNumber?.internationalFormat ||
        this.data?.mobileNumber?.numberWithCallingCode
      );
    },
    photo() {
      return this.data?.profilePicture?.url;
    },
    name() {
      if (this.data?.name) {
        return this.data.name;
      }
      if (this.data?.whatsapp?.name) {
        if (!this.data.whatsapp.name.startsWith("+")) {
          return this.data.whatsapp.name;
        } else {
          return null;
        }
      }
      return null;
    },

    profileRoute() {
      return {
        name: this.type,
        params: {
          id: this.data.id,
        },
      };
    },
    isLink() {
      return this.hasLink && !this.data.isOutOfQuota ? true : false;
    },
  },
};
</script>
