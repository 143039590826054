<template>
  <VpField inline label="Show Archived Items">
    <VpSwitch
      :value="value"
      @input="$emit('input', $event)"
      class="switch--xs switch--primary switch--pill"
    />
  </VpField>
</template>

<script>
import VpField from "../form/field.vue";
import VpSwitch from "../form/switch.vue";
export default {
  components: {
    VpField,
    VpSwitch,
  },
  props: {
    value: Boolean,
  },
};
</script>
