<template>
  <div
    class="vpr-table-select"
    :class="{ 'vpr-table-select--selected': state != 'none' }"
  >
    <p class="vpr-table-select__index">{{ index }}</p>
    <button class="vpr-table-select__check">
      <Icon :name="$options.icons[state]" @click.native="$emit('change')" />
    </button>
  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxIntermediate,
  CheckboxChecked,
} from "icons/icons.js";

import Icon from "../icon.vue";

export default {
  icons: {
    all: CheckboxChecked,
    some: CheckboxIntermediate,
    none: Checkbox,
  },
  components: {
    Icon,
  },
  props: {
    index: [String, Number],
    state: String,
  },
};
</script>

<style>
.vpr-table-select {
  min-width: 20px;
  min-height: 20px;
}

.vpr-table-select__check {
  display: none;
  color: theme("colors.gray.500");
  width: theme("space.5");
  size: theme("space.5");
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}

.vpr-table-select--selected {
  .vpr-table-select__check {
    display: block;
    color: theme("colors.warning.500");
  }
  .vpr-table-select__index {
    display: none;
  }
}
</style>
