<template>
  <div class="vp-w-7 vp-h-7">
    <div class="vp-relative vp-w-full vp-h-full">
      <Icon
        class="vp-w-full vp-h-full vp-text-gray-500 vp-animate-spin vp-absolute vp-z-10 vp-inset-0"
        :name="$options.icons.Loader"
      />
      <Icon
        class="vp-w-full vp-h-full vp-text-gray-200 vp-absolute vp-inset-0"
        :name="$options.icons.Radio"
      />
    </div>
  </div>
</template>

<script>
import { Loader, Radio } from "icons/icons.js";

import Icon from "./icon.vue";

export default {
  icons: {
    Loader,
    Radio,
  },
  components: {
    Icon,
  },
  props: {
    overlay: Boolean,
  },
};
</script>
