<template>
  <button
    class="vp-flex vp-items-center vp-justify-center vp-cursor-pointer vp-group"
    :class="{ 'vp-w-12': !label, 'vp-px-4': label }"
  >
    <span class="vp-block vp-relative">
      <Icon
        class="vp-w-6 vp-h-6 vp-relative vp-z-10"
        :class="
          ping
            ? 'vp-text-warning-400 group-hover:vp-text-warning-900'
            : `vp-text-gray-400 group-hover:vp-text-gray-900`
        "
        :name="loading ? 'IconLineMdLoadingLoop' : icon"
      />
      <span
        v-if="ping"
        class="vp-w-full vp-h-full vp-rounded-full vp-bg-warning-400 vp-animate-ping vp-absolute vp-left-0 vp-top-0"
      ></span>
    </span>
    <span
      :class="`vp-ml-1 vp-font-semibold vp-text-gray-600 group-hover:vp-text-gray-900`"
      v-if="label"
    >
      {{ label }}
    </span>
  </button>
</template>

<script>
import Icon from "../icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    icon: {},
    ping: Boolean,
    label: String,
    loading: Boolean,
  },
};
</script>
