<template>
  <VpList
    :per-page="25"
    :columns="columns"
    endpoint="exportHistory"
    :request-payload="{ query: $options.exportHistory }"
    cache="exportHistory"
    :params="params"
    class="vp-h-full"
    ref="list"
    title="Export History"
    :search="false"
    :feature="feature"
  >
    <template #empty>
      <VpFeature :the-key="feature" #default="{ feature: _feature }">
        <VpListEmpty :feature="_feature" />
      </VpFeature>
    </template>
    <template #status="{ item }">
      <status :value="item.status" />
    </template>
    <template #createdAt="{ item }">
      <VpDatetime :value="item.createdAt" class="vp-text-xs vp-text-gray-500" />
    </template>
    <template #download="{ item }">
      <VyButton
        v-if="item.status === 'success'"
        :icon="$options.icons.Download"
        :label="item.fileName"
        :href="item.url"
        target="_blank"
        class="button--gray button--sm"
      />
    </template>
  </VpList>
</template>

<script>
import Status from "./process-excel/status.vue";
import { Download } from "icons/icons.js";
import exportHistory from "graph/export/history.gql";

export default {
  exportHistory,
  icons: {
    Download,
  },
  data() {
    return {};
  },
  components: {
    Status,
  },
  props: {
    module: String,
    product: String,
    feature: String,
  },
  computed: {
    params() {
      const data = {
        // module: this.module,
        product: this.product,
      };
      return data;
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "status", type: "string" },
        { name: "download", type: "string" },
        { name: "createdAt", type: "string" },
      ];
    },
  },
};
</script>
