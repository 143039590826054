<template>
  <VyCheckboxGroup
    v-bind="$attrs"
    :options="options"
    @input="$emit('input', $event)"
    :value="value"
    class="vp-space-y-1"
  >
    <template #default="{ option, value: _value, input }">
      <VyCheckbox
        :value="option.value"
        :group-value="_value"
        :label="option.label"
        @input="input($event)"
        class="choice--md"
      />
    </template>
  </VyCheckboxGroup>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: Array,
  },
};
</script>
