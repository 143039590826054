<template>
  <div v-if="error" class="error">
    <div
      class="vp-p-4 vp-radius-3 vp-bg-danger-100 vp-mb-3 vp-break-words vp-rounded"
      title="There were some errors!"
      color="danger"
      theme="muted"
      fluid
    >
      <h5 class="vp-text-danger-500 vp-font-semibold">
        {{ message }}
      </h5>
      <div class="vp-text-danger-800">
        <template v-for="field in errors">
          <template v-if="Array.isArray(field)">
            <p v-for="(error, index) in field" :key="`${field}-${index}`">
              {{ error }}
            </p>
          </template>
          <template v-else>
            <p :key="`${field}`">{{ field }}</p>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
    error: String,
  },
  computed: {
    message() {
      if (this.error === "validation") {
        return "Invalid Data Provided.";
      } else {
        return this.error;
      }
    },
  },
};
</script>
