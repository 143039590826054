<template>
  <div>
    <UpgradeAlert
      v-if="!isSubscription"
      :title="title"
      class="vp-mb-6"
      :feature="feature"
    />
    <div class="vp-relative">
      <!-- OVERLAY  -->
      <div
        v-if="!isSubscription"
        class="vp-absolute vp-inset-0 vp-bg-white/50 vp-z-10 vp-cursor-not-allowed"
      ></div>
      <slot :enabled="!isSubscription" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpgradeAlert from "../upgrade-alert.vue";
export default {
  components: {
    UpgradeAlert,
  },
  props: {
    title: String,
    feature: String,
    action: String,
  },

  computed: {
    ...mapGetters({
      checkSubscription: "user/checkSubscription",
    }),
    isSubscription() {
      return this.checkSubscription(this.feature).hasAccess;
    },
  },
  methods: {},
};
</script>
