<template>
  <div>
    <div class="error" v-if="!fromExtension">
      <div
        class="vp-p-6 vp-rounded-md vp-bg-warning-100 vp-mb-4 vp-border vp-border-warning-300"
        title="There were some errors!"
      >
        <div class="vp-text-danger-800">
          <div class="vp-flex vp-items-center">
            <div>
              <Icon
                :name="$options.icons.Alert"
                class="vp-w-14 vp-h-14 vp-me-2"
              />
            </div>
            <div>
              <p class="vp-text-warning-500 vp-font-semibold vp-text-lg">
                Oops!
              </p>
              <p class="vp-text-gray-600 vp-text-base vp-font-medium">
                Unauthorized, You don’t have the access to this feature. Please
                ask admin for the further approvals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="error" v-else>
      <div
        class="vp-p-6 vp-rounded-md vp-bg-warning-100 vp-mb-4 vp-border vp-border-warning-300"
        title="There were some errors!"
      >
        <div class="vp-text-danger-800">
          <div class="vp-flex vp-mb-2">
            <Icon :name="$options.icons.Alert" class="vp-w-7 vp-h-7 vp-me-2" />
            <p class="vp-text-warning-500 vp-font-semibold vp-text-xl">Oops!</p>
          </div>
          <p class="vp-text-gray-600 vp-text-xs vp-font-medium">
            Unauthorized, You don’t have the access to this feature. Please ask
            admin for the further approvals.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert } from "icons/icons.js";
import Icon from "./icon.vue";

export default {
  components: {
    Icon,
  },
  icons: {
    Alert,
  },
  props: {
    fromExtension: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
