<template><div></div></template>
<script>
export default {
  config: { key: "b8708302-8163-49ee-8f16-a6dcb9128a2a" },
  data() {
    return {
      widget: null,
      config: null,
    };
  },
  mounted() {
    this.setupFrillWidget();
  },
  methods: {
    setupFrillWidget() {
      this.config = {
        key: "b8708302-8163-49ee-8f16-a6dcb9128a2a", // <-- Add Widget key here
        callbacks: {
          onReady: (frillWidget) => {
            this.widget = frillWidget;
          },
        },
      };
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(this.config);
      if ("Frill" in window) {
        this.widget = window.Frill.widget(this.config);
      }
    },
  },
  beforeDestroy() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = undefined;
    }

    if (this.config) {
      window.Frill_Config = window.Frill_Config.filter(
        (c) => c !== this.config
      );
    }
  },
};
</script>
