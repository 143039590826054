<template>
  <VpModal
    title="Switch your Legacy Plan!"
    name="legacy-plan"
    port="modal"
    _class="vp-w-1/3"
  >
    <div class="vp-p-4">
      <p class="vp-text-sm vp-text-gray-500">
        Upgrade your account with Vepaar's New Plans to gain access to features
        such as Roles & Permissions and much more.
      </p>
      <div class="vp-flex vp-justify-end vp-mt-2">
        <VyButton
          :icon="$options.icons.Upgrade"
          label="Upgrade Now"
          class="button--solid button--md button--rounded button--warning"
          @click.native="redirectTo"
        />
      </div>
    </div>
  </VpModal>
</template>
<script>
import { Upgrade } from "icons/icons.js";

export default {
  icons: {
    Upgrade,
  },
  methods: {
    redirectTo() {
      this.$vayu.modal.close("legacy-plan");
      window.open(
        `${import.meta.env.VITE_WEB_URL}/#/redirect?redirect=subscription-all`
      );
    },
  },
};
</script>
