<template>
  <VySwitch
    :value="value"
    :id="id"
    :onValue="onValue"
    :offValue="offValue"
    class="switch--sm switch--primary switch--pill"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: false,
    },
    id: {
      type: String,
      default: "status-input",
    },
    onValue: {
      type: [Boolean, Number, String],
      default: true,
    },
    offValue: {
      type: [Boolean, Number, String],
      default: false,
    },
  },
};
</script>
