<template>
  <div class="vp-bg-primary-100 vp-p-2 vp-rounded-full vp-inline-block">
    <div class="vp-bg-primary-300 vp-p-2 vp-rounded-full vp-inline-block">
      <div
        class="vp-bg-primary-500 vp-w-20 vp-h-20 vp-rounded-full vp-flex vp-justify-center vp-items-center vp-text-white"
      >
        <Icon
          class="vp-w-10 vp-h-10 vp-block"
          :name="icon || $options.icons.Alert"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Alert } from "icons/icons.js";
import Icon from "./icon.vue";

export default {
  props: {
    icon: {},
  },
  components: {
    Icon,
  },
  icons: {
    Alert,
  },
};
</script>
