<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 0L3 7l1.63 1.27L12 14l7.36-5.73L21 7zm7.37 10.73L12 16.47l-7.38-5.73L3 12l9 7l9-7zm0 5L12 21.47l-7.38-5.73L3 17l9 7l9-7z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiLayersTriple",
};
</script>
