<template>
  <p>Please wait while we open Vepaar...</p>
</template>

<script>
// Open Routes coming from Extension
import qs from "qs";
import { setToken, setCampaign } from "plugins/utils";

export default {
  mounted() {
    /**
     * If the token is provided in URL
     * Try to login with that token directly.
     */
    const token = this.$route.query?.token;
    const campaign = this.$route.query?.campaign;
    if (token) {
      this.afterLogin(token, campaign);
    } else {
      // Navigating after logging in from crm or store, no need to pass token. directly redirect
      this.redirect();
    }
  },

  methods: {
    afterLogin(token, campaign) {
      setToken(token);
      setCampaign(campaign);
      this.redirect();
    },

    redirect() {
      if (this.$route.query.redirect) {
        //This is browser level query
        const query = qs.parse(this.$route.query);

        const route = {
          name: query.redirect,
          params: query?.params, // this is vue params
          query: query?.query ?? undefined, // this is vue query
        };
        this.$router.push(route);
      } else this.$router.push("/");
    },
  },
};
</script>
