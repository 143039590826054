<template>
  <div
    class="vp-relative vp-flex vp-items-center vp-group"
    :class="{
      'vp-bg-gray-100': isRead,
    }"
  >
    <div
      class="vp-flex vp-items-center vp-flex-grow vp-p-4 vp-cursor-pointer"
      @click="$emit('redirect')"
    >
      <div
        class="vp-h-12 vp-w-12 vp-flex vp-rounded-full vp-items-center vp-flex-shrink-0 vp-justify-center"
        :class="{
          'vp-bg-gray-200 vp-text-gray-400': isRead,
          'vp-bg-primary-100 vp-text-primary-400': !isRead,
        }"
        v-if="icon"
      >
        <Icon class="vp-w-7 vp-h-7" :name="icon" />
      </div>
      <div class="vp-flex vp-flex-col vp-ml-3 vp-mr-4">
        <p class="vp-font-bold vp-leading-tight">{{ title }}</p>
        <p class="vp-text-sm vp-text-gray-500 vp-line-clamp-4">
          {{ description }}
        </p>

        <Datetime
          :value="createdAt"
          class="vp-text-xs vp-mt-1 vp-text-gray-400"
        />
      </div>
    </div>

    <div class="vp-absolute vp-right-4">
      <VyButton
        v-if="!isRead"
        class="vp-flex-shrink-0 vp-ml-auto vp-opacity-0 group-hover:vp-opacity-100 button--primary button--sm"
        :class="{ 'button--circle button--muted': iconName }"
        :icon="iconName"
        :label="buttonLabel"
        v-tooltip.bottom-end="{
          content: 'Mark as read',
          delay: { show: 500, hide: 0 },
        }"
        @click.native="
          $emit('mark-as-read', {
            id,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import { Ticket, Cart, Reminder, Product, OpenInNew } from "icons/icons.js";
import Icon from "./icon.vue";
import Datetime from "./datetime.vue";

export default {
  components: {
    Icon,
    Datetime,
  },
  props: {
    id: [String, Number],
    title: String,
    description: String,
    type: String,
    createdAt: String,
    isRead: Boolean,
    buttonLabel: {
      type: String,
    },
    iconName: {
      type: [String, Object],
    },
  },
  computed: {
    icon() {
      let icon = "";
      switch (this.type) {
        case "TICKET":
          icon = Ticket;
          break;
        case "ORDER_CREATED":
          icon = Cart;
          break;
        case "REMINDER":
          icon = Reminder;
          break;
        case "STOCK_UPDATED":
          icon = Product;
          break;
        default:
          icon = OpenInNew;
      }
      return icon;
    },
  },
};
</script>
