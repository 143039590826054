<template>
  <VyButton
    type="button"
    class="button--rounded"
    v-bind="$attrs"
    :label="labelWithEmoji"
    :class="$options.colorClassesMuted[color]"
  />
</template>

<script>
import colorClassesMuted from "static/src/muted-button-color-classes.json";

export default {
  colorClassesMuted,
  props: {
    color: String,
    emoji: String,
    label: String,
  },
  computed: {
    labelWithEmoji() {
      if (this.emoji) {
        return `${this.emoji} ${this.label}`;
      } else {
        return this.label;
      }
    },
  },
};
</script>
