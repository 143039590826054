<template>
  <VpProFeature :title="`${moduleName} Bulk`" :feature="feature">
    <VpPageHeader
      v-if="action == 'create'"
      class="vp-mb-6"
      title="Bulk Import"
    />
    <VpPageHeader v-else class="vp-mb-6" title="Bulk Update" />

    <ApiError class="vp-mb-6" :data="error" />

    <VpAlert
      fluid
      class="vp-mb-6"
      color="success"
      theme="muted"
      title="✅ Uploaded & Processing..."
      v-if="success"
    >
      <p>You file has been uploaded and being processed.</p>
      <p>You can check the process, status and log in the 'History' section.</p>
    </VpAlert>

    <!-- New -->
    <div class="vp-grid vp-grid-cols-3 vp-gap-6">
      <!-- Step 1 -->
      <Step :no="1">
        <p v-if="action == 'create'">
          Download the blank template file (Excel Sheet) with pre-configured
          columns.
        </p>
        <p v-else>
          Download the Excel Sheet with all the data you've already added.
        </p>
        <template #action>
          <VpButtonMd
            v-if="action == 'create'"
            :icon="$options.icons.Download"
            label="Download"
            :href="sampleUrl"
            target="_blank"
            :loading="isLoading"
            class="vp-w-full"
          />

          <VpButtonMd
            v-else
            :icon="$options.icons.Download"
            label="Download"
            @click.native="exportExcel"
            :loading="exporting"
            class="vp-w-full"
          />
        </template>
      </Step>

      <!-- Step 2 -->
      <Step :no="2">
        <p>
          Update it with the valid data without altering the structure of the
          file.
        </p>
        <p class="vp-mt-2">
          Microsoft Excel is recommended for making changes in file.
        </p>
      </Step>

      <!-- Step 3 -->
      <Step :no="3">
        <p>Upload file and wait for the process to complete.</p>
        <p class="vp-mt-2">
          Once processed, you'll be able to see the report of inserted items.
        </p>
        <input
          type="file"
          class="vp-hidden"
          ref="file"
          accept=".xls,.xlsx"
          @input="upload($event)"
          onclick="this.value = null"
        />

        <template #action>
          <VpButtonMd
            label="Upload"
            :icon="$options.icons.Upload"
            :loading="uploading"
            @click.native="
              checkPermission(feature, 'upsert', $refs.file.click())
            "
            class="vp-w-full"
          />
        </template>
      </Step>
    </div>

    <VpPageHeader class="vp-mt-6" title="Heads Up!">
      <slot name="headsup" />
    </VpPageHeader>
  </VpProFeature>
</template>

<script>
import { mapGetters } from "vuex";
import Step from "./_step.vue";
import generateExcel from "./generate-excel.gql";
import { Download, Upload } from "icons/icons.js";
import ApiError from "../api-error.vue";

export default {
  icons: {
    Download,
    Upload,
  },
  props: {
    module: String,
    action: String,
    mutationQuery: [String, Object],
    exportQuery: [String, Object],
    feature: String,
  },
  components: {
    Step,
    ApiError,
  },
  data() {
    return {
      loading: false,
      uploading: false,
      error: null,
      success: false,
      exporting: false,
      isLoading: false,
      sampleUrl: null,
    };
  },
  mounted() {
    this.getSampleData();
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
      checkPermission: "user/checkPermission",
    }),

    moduleName() {
      return this.module.charAt(0).toUpperCase() + this.module.slice(1);
    },
  },
  methods: {
    async upload(e) {
      this.uploading = true;
      this.error = null;
      this.success = false;
      try {
        await this.$upload(this.mutationQuery, {
          module: this.module,
          action: this.action,
          media: e.target.files?.[0],
        });

        const actionName = this.action === "create" ? "Import" : "Update";
        this.$track(`${this.moduleName} Bulk ${actionName}`, {
          "Store Id": this.storeId,
        });
        this.success = true;
      } catch (err) {
        this.error = err;
        console.error(err);
      }
      this.uploading = false;
    },

    async exportExcel() {
      this.exporting = true;
      try {
        const { data } = await this.$query(this.exportQuery, {
          module: this.module,
          action: this.action,
        });
        const url = data.exportExcel.url;
        window.open(url, "_blank");
      } catch (err) {
        this.error = err;
        console.error(err);
      }
      this.exporting = false;
    },

    async getSampleData() {
      this.isLoading = true;
      try {
        const { data } = await this.$query(generateExcel, {
          module: this.module,
        });
        this.sampleUrl = data.generateExcel.url;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
