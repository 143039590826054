<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M5 2h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1m1 2v8h12V4zm14 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1h16zm0 4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1h16z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiCardsVariant",
};
</script>
