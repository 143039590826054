<template>
  <Modal
    title="✋ It's time to upgrade!"
    _class="vp-w-1/3"
    name="upgrade-notice-modal"
    @open="open($event)"
  >
    <div class="vp-p-6 vp-rounded-xl">
      <p class="vp-text-gray-500">
        You've reached the usage limit of your plan. The limits are shown as
        below. Kindly upgrade to keep using all the features.
      </p>
      <VyTable
        class="vp-mt-4 vp-w-full table--relaxed table--border-y table--border-edge"
      >
        <template #default>
          <tr>
            <th class="vp-text-left">Feature</th>
            <th class="vp-text-right">Included</th>
            <th class="vp-text-right">Remaining</th>
          </tr>
          <tr>
            <td class="vp-text-left">{{ modifiedFeatureName }}</td>
            <td class="vp-text-right">{{ usage?.limit }}</td>
            <td class="vp-text-right">{{ usage?.left }}</td>
          </tr>
        </template>
      </VyTable>

      <VyButton
        @click.native="redirect"
        :icon="$options.icons.Upgrade"
        label="Upgrade Now"
        class="vp-mt-2 button--warning button--solid button--md button--rounded"
      />
    </div>
  </Modal>
</template>

<script>
import { startCase, camelCase } from "lodash-es";
import { Upgrade } from "icons/icons.js";
import Modal from "./modal.vue";
import { toSubscription } from "plugins/utils.js";

export default {
  components: {
    Modal,
  },

  icons: {
    Upgrade,
  },

  data() {
    return {
      usage: {},
      featureName: null,
    };
  },
  computed: {
    modifiedFeatureName() {
      return startCase(camelCase(this.featureName));
    },
  },
  methods: {
    open(res) {
      this.usage = res.usage;
      this.featureName = res.featureName;

      this.$track("Feature Limitation Reach", {
        Feature: this.featureName,
        "Feature Usage": this.usage.used,
        "Feature Remaining Count": this.usage.left,
        "Feature Included Count": this.usage.limit,
      });
    },
    redirect() {
      this.$vayu.modal.close("upgrade-notice-modal");

      toSubscription();
      this.$track("Upgrade Button Clicked", {
        Source: "Popup",
        Feature: this.featureName,
      });
    },
  },
};
</script>
