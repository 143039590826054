<template>
  <VyRadioGroup
    :value="value"
    :options="options"
    @input="$emit('input', $event)"
  >
    <template #default="{ option, value, input }">
      <button type="button" @click="input(option.value)">
        <slot name="default" :option="option" :value="value" :input="input">
        </slot>
      </button>
    </template>
  </VyRadioGroup>
</template>

<script>
export default {
  props: {
    value: [String, Boolean, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    options: Array,
  },
};
</script>
