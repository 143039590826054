<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
    cache="integration"
    feature="INTEGRATION_ZOHO"
  >
    <VpPageHeader title="Zoho" />

    <VpStatusInput id="zoho-status" v-model="state.status" />

    <template>
      <VpField label="Connected As">
        <div
          v-if="response.user && !connectingUser"
          class="vp-bg-gray-100 vp-rounded-xl vp-p-6 vp-gap-3"
        >
          <VpField label="Id">
            <p>{{ response.user.id }}</p>
          </VpField>

          <VpField label="Name">
            <p>{{ response.user.name }}</p>
          </VpField>

          <VpField label="Email">
            <p>{{ response.user.email }}</p>
          </VpField>

          <div>
            <VyButton
              type="button"
              label="Disconnect"
              :loading="disconnectingUser"
              @click.native="
                checkPermission('INTEGRATION_ZOHO', 'delete', () => {
                  disconnect(response);
                })
              "
              class="button--warning button--solid button--sm button--rounded"
            />
          </div>
        </div>

        <div v-else>
          <VyButton
            type="button"
            :loading="connectingUser"
            label="Connect to Zoho"
            @click.native="redirect(response)"
            class="button--secondary button--solid button--md button--rounded"
          />
        </div>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import deleteUser from "./delete.gql";
import updateUser from "./update-user.gql";
import get from "./get.gql";
import updateConfig from "./update-config.gql";

export default {
  track: {
    id: {
      key: "Provider",
      value: () => "Zoho",
    },
  },
  components: {},

  data() {
    return {
      fields: ["status"],
      connectingUser: false,
      disconnectingUser: false,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    redirect(response) {
      window.location.href = response.loginEndpoint;
    },
    async disconnect() {
      try {
        this.disconnectingUser = true;
        await this.$mutate(deleteUser);
        this.$refs.item.refresh();
      } catch (err) {
        console.error(err);
      }
      this.disconnectingUser = false;
    },
    async checkForCode() {
      const params = new URLSearchParams(window.location.href);
      const paramValues = new Proxy(params, {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const code = paramValues.code;
      const location = paramValues.location;
      if (code && location) {
        this.connectingUser = true;
        try {
          await this.$mutate(updateUser, {
            input: {
              code,
              location,
            },
          }).then(() => {
            this.connectingUser = false;
            this.$refs.item.refresh();
            window.history.replaceState(
              {},
              document.title,
              "/#" + this.$route.path
            );
          });
        } catch (err) {
          this.connectingUser = false;
          this.$vayu.notify({
            message: err.graphQLErrors[0].message,
            state: "danger",
          });
        }
      }
    },
    get() {
      return this.$query(get).then(({ data }) => {
        if (this.checkPermission("INTEGRATION_ZOHO", "upsert")) {
          this.checkForCode();
        }
        return {
          values: data.integration.zoho,
          res: data.integration,
        };
      });
    },
    save(id, data) {
      return this.$mutate(updateConfig, {
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$root.$emit("rehydrate", undefined, true);
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
