<template>
  <!-- TODO: Remove Lowercase condition once all the data is migrated -->
  <RadioGroup
    class="vp-flex"
    :value="value && value.toLowerCase()"
    :options="genderOptions"
    @input="$emit('input', $event)"
  />
</template>

<script>
import RadioGroup from "./radio-group.vue";

export default {
  components: {
    RadioGroup,
  },

  props: {
    value: String,
    unselect: Boolean,
  },
  data() {
    return {
      genders: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
        {
          label: "Other",
          value: "other",
        },
      ],
    };
  },
  computed: {
    genderOptions() {
      const options = this.genders;
      if (this.unselect) {
        options.push({
          label: "Not Specified",
          value: "",
        });
      }
      return options;
    },
  },
};
</script>
