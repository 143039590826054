<template>
  <Popover v-bind="$attrs" class="vp-dropdown">
    <template #trigger="{ toggle }">
      <ColorButton
        v-if="selected"
        :label="`${selected.label}`"
        :color="selected.color"
        :emoji="selected.emoji"
        class="button--left button--gray button--md vp-w-full"
        @click.native="toggle()"
      />
      <VyButton
        type="button"
        v-else
        :label="placeholder"
        class="button--gray button--muted button--md button--right button--rounded vp-w-full vp-justify-between"
        :icon="$options.icons.ChevronDown"
        @click.native="toggle()"
      />
    </template>

    <template #default="{ toggle }">
      <!-- "isVisible" is an alias for "shouldDisplayOnStore" -->
      <div class="vp-dropdown__options vp-p-2">
        <ColorButton
          v-for="(option, index) in options"
          :label="option.label"
          :color="option.color"
          :key="`funnel-${index}-${option.id}`"
          :class="{
            'vp-dropdown__options--disabled': option.isVisible === false,
          }"
          class="button--left button--md vp-w-full vp-p-2 vp-mb-2"
          :icon="option.isVisible === false ? $options.icons.Lock : ''"
          @click.native="
            $emit('input', option.id, option);
            toggle();
          "
        />
        <VyButton
          type="button"
          v-if="unselect && value != null"
          label="Remove"
          split
          class="button--md button--gray button--muted button--right button--rounded vp-w-full vp-p-2 vp-mb-2"
          :icon="$options.icons.Close"
          @click.native="
            $emit('input', null);
            toggle();
          "
        />
        <div v-if="!options.length && canAddNew" class="vp-text-center vp-p-2">
          <p class="vp-text-xs vp-text-gray-500 vp-mb-1" v-if="addNewMessage">
            {{ addNewMessage }}
          </p>
          <VyButton
            type="button"
            :label="addNewLabel"
            class="button--gray button--muted button--sm button--rounded"
            :icon="$options.icons.Add"
            @click.native="$emit('onAddNew')"
          />
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import { Close, ChevronDown, Lock, Add } from "icons/icons.js";
import ColorButton from "./color-button.vue";
import Popover from "./popover.vue";

export default {
  components: {
    ColorButton,
    Popover,
  },
  icons: {
    Close,
    ChevronDown,
    Lock,
    Add,
  },
  props: {
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array,
    value: [String, Number],
    unselect: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "sm",
    },
    addNewMessage: {
      type: String,
    },
    addNewLabel: {
      type: String,
      default: "Add New",
    },
    canAddNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected() {
      return this.options.find((option) => option.id == this.value);
    },
  },
};
</script>
