<template>
  <VyInput v-bind="$attrs" class="input--rounded" :class="$options.sizes[size]">
    <slot />
  </VyInput>
</template>

<script>
const sizes = {
  md: "input--md",
  sm: "input--sm",
};

export default {
  sizes,
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>
