<template>
  <span
    v-if="value && relative"
    v-tooltip="{
      content: tooltip ? dateValue : null,
      delay: { show: 500, hide: 0 },
    }"
  >
    {{ relativeValue }}
  </span>
  <span v-else-if="value">{{ dateValue }}</span>
</template>

<script>
import { format, formatDistanceStrict } from "date-fns";

export default {
  props: {
    value: [Number, Boolean, String],
    relative: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    relativeValue() {
      return formatDistanceStrict(new Date(this.value), new Date(), {
        addSuffix: true,
      });
    },
    dateValue() {
      return format(new Date(this.value), "dd/MM/yyyy");
    },
  },
};
</script>
