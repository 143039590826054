<template>
  <div
    class="vp-px-6 vp-py-4 vp-bg-warning-500 vp-flex vp-flex-nowrap vp-items-center vp-relative"
    v-if="show"
  >
    <div class="vp-absolute vp-right-0 vp-top-0">
      <VyButton
        :icon="$options.icons.Close"
        class="button--black button--sm vp-text-xl"
        @click.native="dismiss"
      />
    </div>
    <div>
      <h4 class="vp-text-warning-200 vp-text-lg vp-font-bold">
        You downgraded your subscription plan.
      </h4>
      <p class="vp-text-warning-200">
        <span>
          Some limitations may be applied to the features you are using due to a
          downgrade of your plan.
        </span>
      </p>
    </div>
    <div class="vp-flex vp-items-center vp-ml-auto vp-pr-4 vp-space-x-2">
      <DocsLink #default="{ productHiddenOnStore }">
        <VyButton
          class="button--warning button--muted button--md button--rounded"
          :href="productHiddenOnStore"
          target="_blank"
          label="Know More"
        >
        </VyButton>
      </DocsLink>
      <VyButton
        label="Upgrade Now"
        @click.native="upgrade"
        class="button--warning button--muted button--md button--rounded"
      />
      <VyButton
        class="button--warning button--muted button--md button--rounded"
        label="Okay"
        @click.native="hide"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { Close } from "icons/icons.js";
import DocsLink from "../docs-link/main.vue";

export default {
  icons: {
    Close,
  },
  components: {
    DocsLink,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      getStorage: "storage/get",
      isDowngraded: "subscription/isDowngraded",
    }),
  },
  mounted() {
    if (this.isDowngraded && !this.getStorage("hideDowngradedAlert")) {
      this.show = true;
    }
  },
  methods: {
    ...mapMutations({
      setStorage: "storage/set",
    }),
    upgrade() {
      this.$router.push({
        name: "subscription-all",
      });
      this.$track("Subscription Downgraded Alert Upgrade", {
        Location: "Top Banner",
      });
    },
    hide() {
      this.show = false;
      this.$track("Subscription Downgraded Alert Hidden", {
        Location: "Top Banner",
      });
    },
    dismiss() {
      this.show = false;
      this.setStorage(["hideDowngradedAlert", true]);
      this.$track("Subscription Downgraded Alert Dismissed", {
        Location: "Top Banner",
      });
    },
  },
};
</script>
