<template>
  <div>
    <h3 class="vp-text-gray-500 vp-mb-2 vp-text-2xl vp-font-bold">Admins</h3>
    <div class="vp-grid vp-grid-cols-3 vp-gap-2">
      <template v-if="!data">
        <div
          v-for="n in 3"
          :key="n"
          class="shimmer vp-h-[72px] w-full vp-rounded-md"
        ></div>
      </template>
      <template v-else>
        <VpConnection
          v-for="admin in data"
          :key="admin.id"
          type="contact"
          :data="admin"
          class="vp-border vp-p-2 vp-rounded-md"
          has-link
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
};
</script>
