import bus from "utils/bus.js";

const state = {
  id: null,
  name: null,
  email: null,
  verifiedAt: null,
  uuid: null,
  permissions: null,
  mobileNumber: null,
  campaignUserId: null,
  campaignCount: null,
  contactProfileTimestamp: null,
  celloReferralCode: null,
};

const getters = {
  name(state) {
    return state.name;
  },
  id(state) {
    return state.id;
  },
  email(state) {
    return state.email;
  },
  uuid(state) {
    return state.uuid;
  },
  isVerified(state) {
    return state.verifiedAt !== null;
  },

  isLoggedIn(state) {
    return state.email ? true : false;
  },

  permissions(state) {
    return state.permissions;
  },

  mobileNumber(state) {
    return state.mobileNumber?.numberWithCallingCode;
  },

  campaignUserId(state) {
    return state.campaignUserId;
  },

  campaignCount(state) {
    return state.campaignCount;
  },

  contactProfileTimestamp(state) {
    return state.contactProfileTimestamp;
  },

  trackingData(state, rootGetters) {
    return {
      email: state.email,
      plan: rootGetters["subscription/planName"],
    };
  },
  celloReferralCode(state) {
    return state.celloReferralCode;
  },

  isUserPermitted: (state) => (featureName, action) => {
    let isPermitted = false;
    if (!featureName || !state.permissions || state.permissions.length == 0) {
      isPermitted = true;
    } else if (state.permissions && state.permissions.length > 0) {
      isPermitted = state.permissions.some((res) => {
        return res.feature.key == featureName && res[action];
      });
    }
    return isPermitted;
  },
  checkPermission:
    (state, getters, rootGetters) => (featureName, action, callback) => {
      const isPermitted = getters.isUserPermitted(featureName, action);
      if (callback != undefined) {
        if (isPermitted && !getters.checkSubscription(featureName).hasAccess) {
          if (rootGetters.subscription.plan.isLegacy) {
            bus.$vayu.modal.open("legacy-plan");
          } else {
            bus.$vayu.modal.open("pro-feature-modal", { featureName });
          }
        } else if (
          isPermitted &&
          getters.checkSubscription(featureName).hasAccess
        ) {
          callback();
        } else {
          bus.$vayu.modal.open("unauthorized-access-modal");
        }
      }
      return isPermitted;
    },
  checkSubscription: (state) => (featureName) => {
    let details = false;
    if (!featureName || !state.permissions || state.permissions.length == 0) {
      details = true;
    } else if (state.permissions && state.permissions.length > 0) {
      state.permissions.find((res) => {
        if (res.feature.key === featureName) {
          details = res.feature?.usage;
        }
      });
    }
    return details;
  },
  // TODO: Improve code of isSubscriptionLimitExceeded function.
  isSubscriptionLimitExceeded:
    (state) =>
    (featureName, isFromExtension = false, updatedUsage = null, callback) => {
      let isExceed = false;
      let usage = {};
      if (!featureName || !state.permissions || state.permissions.length == 0) {
        isExceed = false;
      } else if (state.permissions && state.permissions.length > 0) {
        state.permissions.find((res) => {
          if (res.feature.key === featureName) {
            usage = res.feature?.usage;
            if (
              res.feature?.usage.left == null ||
              res.feature?.usage.left == undefined
            ) {
              isExceed = true;
            } else {
              isExceed = parseInt(res.feature?.usage.left) > 0;
            }
          }
        });
        if (updatedUsage) {
          if (updatedUsage.left == null || updatedUsage.left == undefined) {
            isExceed = true;
          } else {
            isExceed = parseInt(updatedUsage.left) > 0;
          }
        }
        if (isExceed && callback) {
          callback(usage);
        }
        if (!isExceed && !isFromExtension) {
          bus.$vayu.modal.open("upgrade-notice-modal", {
            usage: updatedUsage ? updatedUsage : usage,
            featureName,
          });
        }
      }
      return isExceed;
    },
};

const mutations = {
  set(state, data) {
    const {
      id,
      name,
      email,
      verifiedAt,
      uuid,
      campaignUser,
      campaignCount,
      celloReferralCode,
    } = data;

    state.id = id;
    state.name = name;
    state.email = email;
    state.verifiedAt = verifiedAt;
    state.uuid = uuid;
    state.permissions = campaignUser.permissions;
    state.mobileNumber = campaignUser?.mobile;
    state.campaignUserId = campaignUser.id;
    state.campaignCount = campaignCount;
    state.celloReferralCode = celloReferralCode;
  },
  setContactProfileTimestamp(state, data) {
    state.contactProfileTimestamp = data;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
