<template>
  <VyModal
    ref="modal"
    :title="title"
    @open="$emit('open', $event)"
    v-bind="$attrs"
    :close-on-overlay="false"
    @close="$emit('close')"
  >
    <!-- CONTENT -->
    <template #default="{ scope }">
      <div class="vp-h-full">
        <slot :id="$attrs.id" :data="scope" />
      </div>
    </template>

    <!-- FOOTER -->
    <template #footer="{ close }">
      <footer
        v-if="hasFooter"
        class="vp-flex vp-p-4 vp-border-t vp-border-gray-100 vp-items-center vp-flex-nowrap"
      >
        <div class="vp-flex-auto">
          <slot name="footer-note"></slot>
        </div>

        <div class="vp-flex vp-items-center vp-ml-auto">
          <slot name="footer-actions" :close="close">
            <VyButton
              type="button"
              v-if="reset && $listeners.reset"
              @click.native="$emit('reset')"
              :label="resetLabel"
              class="button--gray button--muted button--md button--rounded"
            />
            <VyButton
              type="button"
              v-if="save && $listeners.save"
              @click.native="$emit('save')"
              :icon="saveIcon"
              :label="saveLabel"
              :loading="saving"
              class="vp-ml-3 button--primary button--solid button--md button--rounded"
            />
          </slot>
        </div>
      </footer>
    </template>
  </VyModal>
</template>

<script>
import { Close } from "icons/icons.js";

export default {
  icons: {
    Close,
  },
  props: {
    title: String,
    saving: Boolean,
    fullHeight: Boolean,
    reset: { type: Boolean, default: true },
    save: { type: Boolean, default: true },
    header: {
      type: Boolean,
      default: true,
    },
    saveLabel: {
      type: String,
      default: "Save",
    },
    resetLabel: {
      type: String,
      default: "Reset",
    },
    saveIcon: {
      type: {},
      default: null,
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasFooter() {
      return (
        this.$listeners.save ||
        this.$listeners.reset ||
        this.$scopedSlots["footer-note"] ||
        this.$scopedSlots["footer-actions"]
      );
    },
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style>
/* TODO: Handle this in better way */
.modal--no-header .modal__header {
  display: none;
}
</style>
