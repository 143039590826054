<template>
  <button
    class="vp-w-10 vp-h-10 vp-flex vp-justify-center vp-items-center vp-text-center vp-font-bold vp-text-gray-500 vp-outline-none hover:vp-bg-gray-100"
    :class="{
      'vp-cursor-not-allowed vp-opacity-50': disabled,
      'vp-bg-gray-100': isActive,
    }"
    :disabled="disabled"
    @click="!isActive && $emit('click')"
  >
    <Icon v-if="icon" class="vp-w-5 vp-h-5" :name="icon" />
    <p v-else>{{ number }}</p>
  </button>
</template>

<script>
import Icon from "../icon.vue";

export default {
  props: {
    disabled: Boolean,
    icon: Object,
    number: Number,
    isActive: Boolean,
  },
  components: {
    Icon,
  },
};
</script>
