<template>
  <Modal
    title="Hold On!"
    _class="vp-w-1/3"
    name="pro-feature-modal"
    @open="open($event)"
  >
    <div class="vp-p-6">
      <UpgradeAlert :feature="feature" />
    </div>
  </Modal>
</template>
<script>
import Modal from "./modal.vue";
import UpgradeAlert from "./upgrade-alert.vue";

export default {
  components: {
    Modal,
    UpgradeAlert,
  },

  data() {
    return {
      feature: "",
    };
  },
  methods: {
    open(scope) {
      this.feature = scope.featureName;
    },
  },
};
</script>
