<template>
  <Modal name="export" title="Export" @close="close" _class="vp-w-2/5">
    <template v-if="checkPermission(feature, 'read')">
      <!-- Background Export -->
      <div v-if="submitted">
        <Alert
          class="vp-rounded-none"
          :icon="$options.icons.Check"
          color="success"
          theme="muted"
          title="Request Sent"
        >
        </Alert>
        <div class="vp-text-gray-500 vp-p-6">
          <p>We're generating your file in background.</p>
          <p>
            Once the file is ready, it will be sent to your registered email id.
          </p>
          <p>You can close this window.</p>
          <VyButton
            v-if="historyRoute"
            :icon="$options.icons.History"
            label="Go To Export History"
            :to="{ name: historyRoute }"
            class="vp-mt-6 button--secondary button--solid button--md button--rounded"
          />
        </div>
      </div>

      <!-- Live Export -->
      <template v-else>
        <Alert
          v-if="downloadUrl"
          class="vp-gap-2 vp-rounded-none"
          :icon="$options.icons.Check"
          color="success"
          theme="muted"
          title="File Generated!"
        />

        <div class="vp-p-6">
          <h3>{{ title }}</h3>
          <p class="vp-text-gray-500 vp-mt-1">
            Click on "Generate File" to start building your file. It may take
            time based on amount of data. Once the file is built, you can
            download it.
          </p>

          <div class="vp-mt-6">
            <template v-if="isDuration">
              <h5 class="vp-text-gray-500 vp-mb-2 vp-font-bold">Duration</h5>
              <DateRangePicker
                label="Duration"
                class="vp-mb-6"
                v-model="duration"
              />
            </template>
            <VyButton
              type="button"
              v-if="downloadUrl"
              label="Download File"
              :loading="generating"
              :href="downloadUrl"
              target="_blank"
              class="button--primary button--solid button--rounded button--md"
            />

            <VyButton
              type="button"
              v-else
              label="Generate File"
              :loading="generating"
              @click.native="generate"
              class="button--primary button--solid button--rounded button--md"
            />
          </div>
        </div>
      </template>
    </template>

    <!-- Locked -->
    <div v-else class="vp-p-6">
      <UpgradeAlert :title="`${title} Export`" :feature="feature" />
    </div>
  </Modal>
</template>

<script>
import exports from "utils/export";
import { mapGetters } from "vuex";
import { History, Check } from "icons/icons.js";
import Modal from "./modal.vue";
import Alert from "./alert.vue";
import UpgradeAlert from "./upgrade-alert.vue";
import DateRangePicker from "./date-range-picker.vue";

export default {
  components: {
    Modal,
    Alert,
    UpgradeAlert,
    DateRangePicker,
  },

  icons: {
    History,
    Check,
  },

  props: {
    endpoint: String,
    params: Object,
    title: String,
    feature: {
      type: String,
      required: true,
    },
    historyRoute: [String, Object],
    isDuration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generating: false,
      downloadUrl: false,
      submitted: false,
      duration: [],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    close() {
      this.downloadUrl = false;
      this.generating = false;
      this.submitted = false;
    },
    async generate() {
      let addParams = {};
      if (this.isDuration) {
        if (!this.duration[0] || !this.duration[1]) {
          this.$vayu.notify({
            state: "danger",
            title: "Validation error!",
            message: "Please provide duration date range to export",
          });
          return;
        } else {
          addParams = {
            filters: {
              duration: {
                start: String(this.duration[0]),
                end: String(this.duration[1]),
                preset: "custom",
              },
            },
          };
        }
      }

      try {
        this.generating = true;
        const query = exports[this.endpoint];
        const res = await this.$query(
          query,
          {
            ...this.params,
            ...addParams,
          },
          {
            noCache: true,
          }
        ).then(({ data }) => {
          const keys = Object.keys(data);
          const queryRes = data[keys[0]];

          return queryRes;
        });
        if (res) {
          this.$track("Export Report", {
            "Report Type": this.title,
          });
          this.submitted = true;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$vayu.notify({
          state: "danger",
          title: "Failed to Export!",
          message:
            "We were unable to generate your file. Contact us to resolve the issue.",
        });
        console.error(err);
      }
      this.generating = false;
    },
  },
};
</script>
