import Vue from "vue";
import {
  apolloClient,
  queryPublic,
  mutatePublic,
  upload,
  gql,
  query,
  mutate,
} from "./apollo";
import { mapGetters } from "vuex";
import bus from "../utils/bus";
import { registerComponents } from "utils/register-components";

import Draggable from "vuedraggable";
Vue.component("Draggable", Draggable);

/**
 * Global Components
 */
import Alert from "components/src/alert.vue";
import AlertCard from "components/src/alert-card.vue";
import Amount from "components/src/amount.vue";
import Avatar from "components/src/avatar.vue";
import Connection from "components/src/connection.vue";
import Date from "components/src/date.vue";
import Datepicker from "components/src/datepicker.vue";
import DateRangePicker from "components/src/date-range-picker.vue";
import Datetime from "components/src/datetime.vue";
import Dropdown from "components/src/dropdown.vue";
import ErrorPage from "components/src/error-page.vue";
import Field from "components/src/form/field.vue";
import File from "components/src/form/file.vue";
import Icon from "components/src/icon.vue";
import Input from "components/src/form/input.vue";
import Media from "components/src/media.vue";
import Modal from "components/src/modal.vue";
import NotificationCard from "components/src/notification-card.vue";
import PageHeader from "components/src/page-header.vue";
import ProductMedia from "components/src/product-media.vue";
import RadioCard from "components/src/radio-card.vue";
import RadioGroup from "components/src/form/radio-group.vue";
import Select from "components/src/form/select.vue";
import SelectRequest from "components/src/form/select-request.vue";
import Status from "components/src/status.vue";
import Switch from "components/src/form/switch.vue";
import TextArea from "components/src/form/textarea.vue";
import TextBox from "components/src/form/textbox.vue";
import UnauthorizedAccess from "components/src/unauthorized-access.vue";
import UpgradeAlert from "components/src/upgrade-alert.vue";
import BulkAccordion from "components/src/vp-list/bulk-accordion.vue";
import BulkAction from "components/src/vp-list/bulk-action.vue";
import ListEmpty from "components/src/vp-list/empty.vue";
import List from "components/src/vp-list/index.vue";
import VueForm from "components/src/vue-form/index.vue";
import LoaderContainer from "components/src/loader-container.vue";
import ProductStock from "components/src/product-stock.vue";
import AddItem from "components/src/vp-list/add-item.vue";
import NavGroup from "components/src/app/nav-group.vue";
import ListFilterArchived from "components/src/vp-list/filter-archived.vue";
import ListLayout from "components/src/vp-list/layout.vue";
import Calendar from "components/src/v-calendar.vue";
import Shell from "components/src/app/shell.vue";
import SubShell from "components/src/app/sub-shell.vue";

// Buttons
import ActionButton from "components/src/button/action.vue";
import ButtonGraySmMuted from "components/src/button/gray-sm-muted.vue";
import ButtonSm from "components/src/button/sm.vue";
import ButtonPill from "components/src/button/pill.vue";
import ButtonPillLg from "components/src/button/pill-lg.vue";

import ButtonMd from "components/src/button/md.vue";
import PortalButton from "components/src/button/portal.vue";

import ImageEditor from "components/src/image-editor.vue";
import HeaderActions from "components/src/feature/actions.vue";
import Request from "components/src/request.vue";
import SubNavItem from "components/src/app/sub-nav-item.vue";
import ProFeature from "components/src/feature/pro.vue";
import StatusInput from "components/src/status-input.vue";
import SubNav from "components/src/app/sub-nav.vue";
import Feature from "components/src/feature/index.vue";

const components = [
  { Icon },
  { Media },
  { UnauthorizedAccess },
  { ErrorPage },
  { Field },
  { PageHeader },
  { History },
  { List },
  { Datetime },
  { Amount },
  { ProductMedia },
  { Status },
  { Connection },
  { Date },
  { Dropdown },
  { AlertCard },
  { Modal },
  { Datepicker },
  { TextBox },
  { TextArea },
  { Select },
  { RadioGroup },
  { RadioCard },
  { Input },
  { SelectRequest },
  { NotificationCard },
  { Switch },
  { Alert },
  { Avatar },
  { UpgradeAlert },
  { File },
  { ListEmpty },
  { BulkAction },
  { BulkAccordion },
  { DateRangePicker },
  { VueForm },
  { HeaderActions },
  { AddItem },
  { Shell },
  { SubShell },
  { Request },
  { PortalButton },
  { ActionButton },
  { SubNavItem },
  { ButtonPillLg },
  { ButtonPill },
  { ButtonSm },
  { ButtonMd },
  { ButtonGraySmMuted },
  { ImageEditor },
  { LoaderContainer },
  { ProductStock },
  { ProFeature },
  { StatusInput },
  { SubNav },
  { Feature },
  { NavGroup },
  { ListFilterArchived },
  { ListLayout },
  { Calendar },
];
registerComponents(Vue, components);

/**
 * Prototypes
 */
Vue.prototype.$gql = gql;
Vue.prototype.$queryPublic = queryPublic;
Vue.prototype.$mutatePublic = mutatePublic;
Vue.prototype.$query = query;
Vue.prototype.$mutate = mutate;
Vue.prototype.$upload = upload;
Vue.prototype.$cache = apolloClient.cache;

Vue.prototype.$slideover = {
  open: (id) => bus.$emit("slideover-open", id),
  close: (id) => bus.$emit("slideover-close", id),
  toggle: (id) => bus.$emit("slideover-toggle", id),
};

/**
 * Mixin
 */

Vue.mixin({
  computed: {
    ...mapGetters({
      analytics: "analytics",
      isSubscriptionActive: "subscription/isActive",
    }),
  },

  methods: {
    $track(name, payload) {
      if (
        import.meta.env.VITE_SEGMENT === "true" &&
        this.isSubscriptionActive
      ) {
        // Segment has monthly tracked users limit.therefore sending data of paid users only. learn more at https://segment.com/pricing/

        window.analytics.track(
          name,
          {
            user_id: this.analytics.userId,
            campaign_id: this.analytics.campaignId,
            "Web Version": this.analytics.version,
            Platform: this.analytics.platform,
            "Subscription Plan ID": this.analytics.subscriptionPlanId,
            "Subscription Plan": this.analytics.subscriptionPlan,
            "Subscription Plan Validity":
              this.analytics.subscriptionPlanValidity,
            ...payload,
          },
          {
            integrations: {
              All: true,
              "OneSignal (New)": false,
            },
          }
        );
      }
    },
  },
});
