<template>
  <div class="vp-relative">
    <component
      :is="to ? 'router-link' : 'figure'"
      :to="to"
      class="vp-aspect-1 vp-overflow-hidden vp-relative vp-m-0 vp-bg-gray-100 vp-flex vp-justify-center vp-items-center vp-rounded-md"
    >
      <img class="vp-w-full vp-h-full vp-object-cover" v-if="src" :src="src" />
      <p class="vp-grayscale vp-opacity-50 vp-text-2xl" v-else>📦️</p>
    </component>
    <VyButton
      class="button--primary button--solid button--circle button--xs vp-absolute -vp-bottom-1 -vp-right-1 vp-ring-2 vp-ring-white"
      v-if="$options.icons[type]"
      :icon="$options.icons[type]"
    />
  </div>
</template>

<script>
import { ProductVariants, Download } from "icons/icons.js";

export default {
  icons: {
    variable: ProductVariants,
    digital: Download,
  },

  props: {
    item: Object,
    items: Array,
    size: {
      type: String,
      default: "500x500",
    },
    to: Object,
    type: String,
  },

  computed: {
    src() {
      if (this.item) {
        return this.item?.size[`_${this.size}`];
      } else if (this.items) {
        return this.items.find((item) =>
          (item.mimetype || "image")?.startsWith("image")
        )?.size[`_${this.size}`];
      }
      return null;
    },
  },
};
</script>
