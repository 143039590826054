<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M4 21h16V8H4m10 7v3h-4v-3H7l5-5l5 5M3 3h18v4H3"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiArchiveArrowUp",
};
</script>
