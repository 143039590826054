<template>
  <Modal
    name="unauthorized-access-modal"
    title="Oops, access denied!"
    _class="vp-w-1/3"
  >
    <div class="vp-p-6">
      <p class="vp-text-gray-500">
        You don't have the access to this feature. Please ask admin for the
        further approvals.
      </p>
      <div class="vp-flex vp-justify-end vp-mt-6">
        <VyButton
          type="button"
          label="OK"
          class="button--primary button--solid button--lg button--rounded vp-w-20"
          @click.native="closeModal"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "./modal.vue";

export default {
  components: {
    Modal,
  },

  methods: {
    closeModal() {
      this.$vayu.modal.close("unauthorized-access-modal");
    },
  },
};
</script>
