<template>
  <VpShell :aside="false">
    <VpFeature the-key="CONTACT" #default="{ feature }">
      <router-view
        :feature="feature"
        :refresh-feature="feature && feature.refresh"
      />
    </VpFeature>
  </VpShell>
</template>

<script>
export default {};
</script>
