<template>
  <div class="vp-animate-pulse vp-w-full" v-if="requestHandler && loading">
    <div class="vp-h-9 vp-w-full vp-bg-shimmer vp-rounded" v-if="loading"></div>
  </div>
  <VySelect
    v-else-if="requestHandler && !loading"
    :value="value"
    v-bind="$attrs"
    :options="options"
    @input="$emit('input', $event)"
  />
  <VySelect
    v-else
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [String, Number],
    requestHandler: Function,
  },

  data() {
    return {
      loading: false,
      options: [],
    };
  },

  created() {
    if (this.requestHandler) {
      this.getOptions();
    }
  },

  methods: {
    refresh() {
      this.getOptions();
    },
    getOptions() {
      this.loading = true;
      this.requestHandler()
        .then((res) => {
          this.options = res;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
