import { isValid } from "date-fns";
import Cookies from "js-cookie";
import qs from "qs";
/**
 * Sharing token, campaignID to subdomains crm , store using cookie
 */

/**
 * passing NODE_ENV development in  dev:qa1 mode
 * for local development instead of manually setting cookie
 *  */
const IS_DEV =
  process.env.NODE_ENV === "development" ||
  import.meta.env.MODE === "development";

const domain = IS_DEV ? ".vepaar.local" : ".vepaar.com";

const AUTH_TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;
const CAMPAIGN_ID_KEY = import.meta.env.VITE_CAMPAIGN_ID_KEY;
const STORE_ID_KEY = import.meta.env.VITE_STORE_ID_KEY;

export const getFileName = (name) => {
  // This method will remove the extension .jpg,.png,etc..
  const lastDotIndex = name.lastIndexOf(".");
  if (lastDotIndex !== -1) {
    return name.substring(0, lastDotIndex);
  } else {
    return name;
  }
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve({ fileName: getFileName(file.name), dataUrl: reader.result });
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getToken = () => {
  const token = Cookies.get(AUTH_TOKEN_KEY);
  return token ? `Bearer ${token}` : undefined;
};

export const setCelloUcc = (value) => {
  Cookies.set("vp-cello-ucc", value, {
    domain,
    expires: 21,
  });
};

export const getCelloUcc = () => {
  Cookies.get("vp-cello-ucc");
};

export const setToken = (value) => {
  if (value) {
    Cookies.set(AUTH_TOKEN_KEY, value, {
      domain,
      expires: 21,
    });
  } else {
    Cookies.remove(AUTH_TOKEN_KEY, {
      domain,
    });
  }
};

export const setCampaign = (value) => {
  Cookies.set(CAMPAIGN_ID_KEY, value, {
    domain,
    expires: 21,
  });
};

export const setStore = (value) => {
  Cookies.set(STORE_ID_KEY, value, {
    domain,
    expires: 21,
  });
};

export const setCelloToken = (value) => {
  Cookies.set("vp-cello-token", value, {
    domain,
    expires: 21,
  });
};

export const getCampaign = () => {
  return parseInt(Cookies.get(CAMPAIGN_ID_KEY));
};

export const getCelloToken = () => {
  return Cookies.get("vp-cello-token");
};

export const getStore = () => {
  const id = Cookies.get(STORE_ID_KEY);
  if (id) return parseInt(id);
  else return null;
};

export const removeCampaign = () => {
  Cookies.remove(CAMPAIGN_ID_KEY, {
    domain,
  });
};

export const removeStore = () => {
  Cookies.remove(STORE_ID_KEY, {
    domain,
  });
};

export const removeCelloToken = () => {
  Cookies.remove("vp-cello-token", {
    domain,
  });
};

export const removeCelloUcc = () => {
  Cookies.remove("vp-cello-ucc", {
    domain,
  });
};

export const toWeb = (route, params, query) => {
  let url = `${import.meta.env.VITE_WEB_URL}/#/redirect?redirect=${route}`;
  if (params) url += `&params=${params}`;

  if (query) url += `&query=${query}`;
  window.open(url, "_self");
};

const redirectLinkGenerator = (redirect, params = null, baseUrl) => {
  let url = `${baseUrl}#/redirect`;

  if (redirect) {
    url += `?redirect=${redirect}`;
  }

  if (params) {
    url += `&${qs.stringify(params)}`;
  }
  return url;
};

export const toCatalog = (route, params) => {
  return redirectLinkGenerator(route, params, import.meta.env.VITE_CATALOG_URL);
};

export const toStore = (route, params) => {
  return redirectLinkGenerator(route, params, import.meta.env.VITE_STORE_URL);
};

export const toSubscription = () => {
  toWeb("subscription-all");
};
export const timeStampToISOString = (timestamp) => {
  const parsedTimeStamp = parseInt(timestamp) * 1000;
  if (isNaN(parsedTimeStamp)) return "-";
  const isTimeStampValid = isValid(new Date(parsedTimeStamp));
  if (isTimeStampValid) {
    return new Date(parsedTimeStamp).toISOString();
  } else {
    return "-";
  }
};

export const queryParamsInURL = (url) => {
  const urlObj = new URL(url);
  const urlSearchParams = new URLSearchParams(urlObj.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export const injectScript = (src, attributes) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;

    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        script.setAttribute(key, value);
      }
    }
    script.addEventListener("load", resolve);
    script.addEventListener("error", (e) => reject(e.error));
    document.head.appendChild(script);
  });
};

export const getOS = () => {
  if (navigator.userAgent.indexOf("Android") != -1) return "android";
  if (navigator.userAgent.indexOf("iPhone") != -1) return "ios";
  if (navigator.userAgent.indexOf("Win") != -1) return "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) return "macos";
  if (navigator.userAgent.indexOf("X11") != -1) return "unix";
  if (navigator.userAgent.indexOf("Linux") != -1) return "linux";
  return null;
};
