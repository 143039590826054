<template>
  <div class="">
    <div class="vp-flex vp-items-center">
      <VyButton
        type="button"
        v-if="backRoute"
        :icon="$options.icons.ArrowLeft"
        class="vp-mr-2 button--gray"
        :to="backRoute"
      />
      <component
        :is="tag"
        class="vp-text-secondary-500 vp-text-lg vp-font-semibold"
      >
        {{ title }}
      </component>
    </div>
    <div class="vp-text-gray-500">
      <slot />
    </div>
  </div>
</template>

<script>
import { ArrowLeft } from "icons/icons.js";

export default {
  icons: {
    ArrowLeft,
  },
  props: {
    title: String,
    tag: {
      type: String,
      default: "h3",
    },
    backRoute: {
      type: Object,
    },
  },
};
</script>
