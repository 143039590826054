<template>
  <h5 class="vp-font-bold">{{ values[value] }}</h5>
</template>
<script>
const values = {
  create: "Import",
  update: "Update",
};
export default {
  props: {
    value: String,
  },
  data() {
    return {
      values,
    };
  },
};
</script>
