<template>
  <VpAlert
    color="warning"
    theme="muted"
    :title="title"
    :icon="$options.icons.Alert"
  >
    <p>{{ desc }}</p>

    <DocsLink #default="{ productHiddenOnStore }">
      <VyButton
        label="Know More"
        :href="productHiddenOnStore"
        target="_blank"
        class="button--warning button--solid button--sm button--rounded vp-mt-2"
      />
    </DocsLink>
  </VpAlert>
</template>

<script>
import { Alert } from "icons/icons.js";
import DocsLink from "./docs-link/main.vue";

export default {
  icons: {
    Alert,
  },

  props: {
    title: String,
    desc: String,
  },

  components: {
    DocsLink,
  },
};
</script>
