<template>
  <svg viewBox="0 0 24 24" widht="1em" height="1em">
    <path
      fill="currentColor"
      d="M13 8.5h-2v-2h2v2m0 4.5h-2v-2h2v2m0 4.5h-2v-2h2v2m9-7.5V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2a2 2 0 0 1-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 1-2-2a2 2 0 0 1 2-2Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiTicketConfirmation",
};
</script>
