<template>
  <button
    class="vp-px-4 vp-py-3 vp-text-gray-500 vp-flex vp-justify-between vp-w-full vp-items-center hover:vp-bg-gray-50"
    @click="onClick()"
  >
    <span>{{ label }}</span>
    <Icon class="vp-w-5 vp-h-5" :name="icon" />
  </button>
</template>

<script>
import Icon from "../icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    label: String,
    icon: {},
    confirm: String,
  },
  methods: {
    onClick() {
      if (this.confirm) {
        if (confirm(this.confirm)) {
          this.$emit("click");
        }
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>
