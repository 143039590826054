<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
    feature="INTEGRATION_FACEBOOK_PIXEL"
  >
    <VpPageHeader title="Facebook Pixel" />
    <VpField label="Pixel ID" name="Pixel ID" rules="required">
      <VpInput>
        <VpTextBox v-model="state.pixelId" placeholder="e.g. 12345678910" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>
<script>
import get from "./get.gql";
import facebookPixel from "./upsert.gql";

export default {
  track: {
    pixelId: {
      key: "Provider",
      value: () => "Facebook Pixel",
    },
  },
  data() {
    return {
      fields: ["pixelId"],
    };
  },
  methods: {
    get() {
      return this.$query(get).then((res) => {
        return {
          values: res.data.integration?.facebookPixel,
          res: res.data.integration,
        };
      });
    },
    save(id, data) {
      let finalData = {
        ...data,
      };
      return this.$mutate(facebookPixel, finalData).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
