import Vue from "vue";
import campaignMobiles from "graph/mobileOptions.gql";
import countryQuery from "graph/countries.gql";
import menuQuery from "graph/menus.gql";
import deleteMediaQuery from "graph/deleteMedia.gql";
import currencyQuery from "graph/currencies.gql";
import productCategory from "graph/productCategory.gql";
import timezoneQuery from "graph/timezones.gql";
import generatePresignedUrl from "graph/generatePresignedUrl.gql";

const app = new Vue();

export const currencyOptions = () => {
  return app
    .$query(currencyQuery)
    .then(({ data }) => data.currencies.data)
    .then((res) => {
      return res.map((item) => {
        let label = `${item.currency} - ${item.abbreviation}`;
        if (item.symbol) label += " - " + item.symbol;
        return {
          label: label,
          value: item.abbreviation,
        };
      });
    });
};

export const countryOptions = () => {
  return app
    .$query(countryQuery)
    .then(({ data }) => data.countries.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const timezoneOptions = () => {
  return app
    .$queryPublic(timezoneQuery)
    .then(({ data }) => data.timezones.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const productCategoryOptions = () => {
  return app
    .$query(productCategory, {
      perPage: -1,
    })
    .then(({ data }) => data.productCategories.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const callingCodes = () => {
  return app
    .$query(countryQuery)
    .then(({ data }) => data.countries.data)
    .then((res) => {
      return res.map((item, index) => {
        return {
          label: `${item.name} — ${item.callingCode}`,
          value: item.callingCode,
          id: `${item.callingCode}-${index}`,
        };
      });
    });
};

export const getMobileNumbers = () => {
  return app
    .$query(campaignMobiles)
    .then(({ data }) => data.campaignMobiles.data);
};

// This fileUploadData object includes {path, file} to upload media to S3.
export const uploadMediaToS3 = async (fileUploadData) => {
  let fileData = fileUploadData.file;
  try {
    if (fileData && fileData.url) {
      const response = await fetch(fileData.url);
      const blobData = await response.blob();

      fileData = new File([blobData], fileData.name, {
        type: fileData.type || blobData.type,
      });
    }
    if (typeof fileData.size != "string") {
      const fileDataWithStringSize = {
        name: fileData.name,
        size: (fileData.size / 1024 / 1024).toFixed(2) + "MB",
        type: fileData.type,
        lastModified: fileData.lastModified,
        lastModifiedDate: fileData.lastModifiedDate,
      };
      fileUploadData["file"] = fileDataWithStringSize;
    }
    const presignedData = await getPresignedData(fileUploadData);
    if (presignedData) {
      await uploadFileToS3(fileData, presignedData);
    }
    return presignedData;
  } catch (error) {
    errorHandler(error);
  }
};

export const getPresignedData = async (fileUploadData) => {
  const { path, file } = fileUploadData;
  let response;
  try {
    response = await app.$mutate(generatePresignedUrl, {
      for: path,
      input: [
        {
          mimetype: file.type,
          name: file.name,
          size: file.size,
        },
      ],
    });
  } catch (error) {
    errorHandler(error);
  }
  return response.data.generatePresignedUrl[0];
};

export const uploadFileToS3 = async (blobData, presignedData) => {
  try {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": blobData.type,
      },
      body: blobData,
    };
    fetch(presignedData.url, options);
  } catch (error) {
    errorHandler(error);
  }
};

export const errorHandler = (error) => {
  console.error("Error:", error);
  if (!error.message) {
    error.message = "Something went wrong. Try again.";
  }
  app.$vayu.notify({
    title: "Error!",
    message: error.message,
    status: "danger",
  });
  throw error;
};

export const deleteMedia = (mediaIds) => {
  if (!mediaIds || (Array.isArray(mediaIds) && !mediaIds.length)) {
    return new Promise((resolve) => resolve(true));
  }

  return app
    .$mutate(deleteMediaQuery, {
      ids: mediaIds,
    })
    .then(({ data }) => data.deleteMedia);
};

export const getMenus = () => {
  return app.$query(menuQuery).then(({ data }) =>
    data.menus.data.map((menu) => ({
      value: menu.id,
      label: menu.name,
    }))
  );
};
