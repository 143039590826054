<template>
  <VpField inline :label="label" :note="desc" v-bind="$attrs">
    <div class="vp-flex">
      <!-- TODO: Is Button Required? -->
      <VyButton
        class="vp-capitalize vp-mr-2"
        :class="buttonClass"
        :label="value == true || value == 'active' ? 'Active' : 'Inactive'"
      />

      <VpSwitch
        :class="disabled ? 'vp-pointer-events-none vp-opacity-50' : null"
        :value="value"
        :id="id"
        :on-value="isEnum ? true : 'active'"
        :off-value="isEnum ? false : 'inactive'"
        @input="$emit('input', $event)"
      />
    </div>
  </VpField>
</template>

<script>
export default {
  props: {
    isEnum: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean],
      default: "active",
    },
    id: {
      type: String,
      default: "status-input",
    },
    label: {
      type: [String, Boolean],
      default: "Status",
    },
    desc: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    size: {
      type: String,
      default: "xs",
    },
  },
  computed: {
    buttonClass() {
      return this.value == "active" || this.value == true
        ? "button--success"
        : "button--warning";
    },
  },
};
</script>
