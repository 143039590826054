const state = {
  plan: null,
  uuid: null,
  validity: null,
  isActive: false,
  isCancellationPending: false,
  isDowngraded: false,
};

const getters = {
  isActive(state) {
    return state.isActive;
  },
  planName(state) {
    return state.plan?.name;
  },
  planId(state) {
    return state.plan?.id;
  },
  validity(state) {
    return state.validity;
  },
  price(state) {
    return state.plan?.price || 0;
  },
  uuid(state) {
    return state.uuid;
  },
  provider(state) {
    return state.plan?.provider;
  },
  isLegacy(state) {
    return state.plan?.isLegacy;
  },
  isCancellationPending(state) {
    return state.isCancellationPending;
  },
  isDowngraded(state) {
    return state.isDowngraded;
  },
  isDifferentChannel(state, getters) {
    return !["paddle", "default", "nexarc"].includes(getters.provider);
  },
  canChange(state, getters) {
    return !getters.isDifferentChannel && !state.isCancellationPending;
  },
};

const mutations = {
  set(state, data) {
    const {
      plan,
      uuid,
      validity,
      isSubscriptionActive,
      isCancellationPending,
      isDowngraded,
    } = data;

    state.plan = plan;
    state.uuid = uuid;
    state.validity = validity;
    state.isActive = isSubscriptionActive;
    state.isCancellationPending = isCancellationPending;
    state.isDowngraded = isDowngraded;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
