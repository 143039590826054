<template>
  <div class="vp-p-6">
    <MessageStatistics :request="get" />

    <div v-if="country" class="vp-flex vp-space-x-8 vp-mt-8">
      <VpField label="Country">
        <p>{{ country.name }}</p>
      </VpField>
      <VpField label="Country ISO">
        <p>{{ country.iso }}</p>
      </VpField>
      <VpField label="TimeZone">
        <p>{{ country.timezone }}</p>
      </VpField>
      <VpField v-if="currentTime" label="Current Time">
        <p>{{ currentTime }}</p>
      </VpField>
    </div>
  </div>
</template>

<script>
import statistics from "graph/contact/statistics.gql";
import MessageStatistics from "./_MessageStatistics.vue";

export default {
  props: {
    id: [String, Number],
  },
  data() {
    return {
      country: null,
    };
  },
  components: {
    MessageStatistics,
  },

  computed: {
    currentTime() {
      if (this.country?.timezone) {
        return new Date().toLocaleString("en-US", {
          timeZone: this.country.timezone,
        });
      }
      return null;
    },
  },
  methods: {
    get() {
      return this.$query(statistics, {
        id: this.id,
      }).then((res) => {
        this.country = res.data.contact.mobileNumber?.country;
        return res.data.contactStatistics.count;
      });
    },
  },
};
</script>
