<template>
  <VpField :label="label">
    <!-- :columns="$screens({ default: 1, lg: 2 })" -->
    <!-- :show-linked-buttons="false" -->
    <DatePicker
      is-range
      @input="input($event, 'datepicker')"
      mode="range"
      :value="range"
      :first-day-of-week="1"
      :max-date="new Date()"
      :input-debounce="500"
      :popover="{
        placement: 'bottom-end',
        visibility: 'click',
        positionFixed: true,
      }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <VyInput
          :before-icon="$options.icons.Calendar"
          class="input--md focus:vp-outline-none input--rounded"
        >
          <input
            class="vp-w-1/2 vp-bg-gray-200 focus:vp-outline-none"
            :value="inputValue.start"
            placeholder="Start"
            v-on="inputEvents.start"
          />
          <input
            class="vp-w-1/2 vp-bg-gray-200 focus:vp-outline-none"
            :value="inputValue.end"
            placeholder="End"
            v-on="inputEvents.end"
          />
        </VyInput>
      </template>
      <template v-slot:footer>
        <div class="vp-grid vp-grid-cols-3 vp-p-2 vp-border-t">
          <template v-for="(duration, index) in durations">
            <VyButton
              v-if="duration.value"
              :key="`${index}`"
              @click.native="setRange(duration.value)"
              :label="duration.label"
              class="button--gray button--xs"
            />
            <div v-else :key="index" />
          </template>
        </div>
      </template>
    </DatePicker>
  </VpField>
</template>

<script>
import { DatePicker } from "v-calendar";
import {
  startOfDay,
  endOfDay,
  sub,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
} from "date-fns";

import { Calendar } from "icons/icons.js";

export default {
  icons: { Calendar },
  components: {
    DatePicker,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    duration: {
      type: String,
      default: null,
    },
    values: Object,
  },
  data() {
    return {
      localDuration: null,
      range: null,
    };
  },
  created() {
    if (!this.value && this.duration) {
      this.setRange(this.duration);
    }
    if (this.values) {
      this.range = {
        start: new Date(this.values.start * 1000),
        end: new Date(this.values.end * 1000),
      };
      this.input(this.range);
    }
  },
  computed: {
    today() {
      return {
        start: startOfDay(new Date()),
        end: sub(endOfDay(new Date()), { seconds: 1 }),
      };
    },
    durations() {
      return [
        {
          label: "Today",
          value: "today",
          start: this.today.start,
          end: this.today.end,
        },
        {
          label: "Yesterday",
          value: "yesterday",
          start: sub(this.today.start, { days: 1 }),
          end: sub(this.today.end, { days: 1 }),
        },

        {
          label: "Last 7 Days",
          value: "last_7_days",
          start: sub(this.today.end, { days: 7 }),
          end: this.today.end,
        },

        {
          label: "This Week",
          value: "this_week",
          start: startOfWeek(this.today.start),
          end: this.today.end,
        },
        {
          label: "Last Week",
          value: "last_week",
          start: startOfWeek(sub(this.today.start, { weeks: 1 })),
          end: endOfWeek(sub(this.today.end, { weeks: 1 })),
        },
        {
          label: "Last 30 Days",
          value: "last_30_days",
          start: sub(this.today.end, { days: 30 }),
          end: this.today.end,
        },
        {
          label: "This Month",
          value: "this_month",
          start: startOfMonth(this.today.start),
          end: this.today.end,
        },
        {
          label: "Last Month",
          value: "last_month",
          start: startOfMonth(sub(this.today.start, { months: 1 })),
          end: endOfMonth(sub(this.today.end, { months: 1 })),
        },
        {
          label: "Last 90 Days",
          value: "last_90_days",
          start: sub(this.today.end, { days: 90 }),
          end: this.today.end,
        },
        {
          label: "This Quarter",
          value: "this_quarter",
          start: startOfQuarter(this.today.start),
          end: this.today.end,
        },
        {
          label: "Last Quarter",
          value: "last_quarter",
          start: startOfQuarter(subQuarters(this.today.start, 1)),
          end: endOfQuarter(startOfQuarter(subQuarters(this.today.end, 1))),
        },
        {},
        {
          label: "This Year",
          value: "this_year",
          start: startOfYear(this.today.start),
          end: this.today.end,
        },
        {
          label: "Last Year",
          value: "last_year",
          start: startOfYear(sub(this.today.start, { years: 1 })),
          end: endOfYear(sub(this.today.end, { years: 1 })),
        },
      ];
    },
  },

  methods: {
    setRange(duration) {
      this.localDuration = duration;
      let range = this.durations.find((item) => item.value == duration);
      if (duration) {
        this.range = range;
        this.input(range);
      }
    },
    input(range) {
      if (!range) return;
      this.$emit("input", {
        start: String(range.start.getTime() / 1000).split(".")[0],
        end: String(range.end.getTime() / 1000).split(".")[0],
      });
    },
  },
};
</script>
