<template>
  <div>
    <button
      class="vp-px-4 vp-py-3 vp-text-gray-500 vp-flex vp-justify-between vp-w-full vp-items-center hover:vp-bg-gray-50"
      @click="isOpen = !isOpen"
    >
      <span>{{ label }}</span>
      <Icon
        class="vp-w-5 vp-h-5"
        :name="
          isOpen ? $options.icons.ChevronDown : $options.icons.ChevronRight
        "
      />
    </button>

    <div v-if="isOpen" class="vp-p-4 vp-overflow-y-auto">
      <slot />
    </div>
  </div>
</template>

<script>
import { ChevronDown, ChevronRight } from "icons/icons.js";
import Icon from "../icon.vue";

export default {
  components: {
    Icon,
  },
  icons: {
    ChevronDown,
    ChevronRight,
  },
  props: {
    label: String,
    confirm: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClick() {
      if (this.confirm) {
        if (confirm(this.confirm)) {
          this.$emit("click");
        }
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>
