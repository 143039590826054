import Cookies from "js-cookie";
import {
  queryParamsInURL,
  timeStampToISOString,
  setStore,
} from "plugins/utils";

const state = {
  credits: null,
  analytics: null,
};

const getters = {
  analytics(state) {
    return state.analytics;
  },

  credits(state) {
    return state.credits;
  },
};

const mutations = {
  hydrated(state, data) {
    const { credits, analytics } = data;
    state.credits = credits;
    state.analytics = analytics;
  },
};

const actions = {
  async hydrate({ commit }, { request, version }) {
    const { data } = await request;
    const { user, campaign, stores, campaignUser } = data;

    try {
      commit("user/set", { ...user, campaignUser }, { root: true });
      commit(
        "biz/set",
        {
          biz: {
            ...campaign,
            id: parseInt(campaign.id),
          },
        },
        { root: true }
      );

      // STORE : Store will not be available when user is creating a fresh account
      const store = stores?.data[0];
      if (store) {
        commit(
          "store/set",
          {
            ...store,
            id: parseInt(store.id),
          },
          { root: true }
        );

        setStore(store.id);
      }

      commit("subscription/set", campaignUser.subscription, { root: true });

      commit("hydrated", {
        credits: campaign.credits,
        analytics: analyticsData(user, campaign, campaignUser, version),
      });

      //Setup Paddle
      if (window.Paddle) {
        window.Paddle.Environment.set(import.meta.env.VITE_PADDLE_ENV);
        window.Paddle.Setup({
          token: import.meta.env.VITE_PADDLE_TOKEN,
          pwAuth: import.meta.env.VITE_PROFITWELL_AUTH_KEY,
          pwCustomer: {
            email: user.email,
          },
        });
      }

      return data;
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

const analyticsData = (user, campaign, campaignUser, version) => {
  const { email, registrationAt, verifiedAt, lastLoginAt, emailAuthHash } =
    user;
  const { credits, subdomain, address, country, desc } = campaign;
  const { subscription } = campaignUser;

  let queryParams = {};
  const gtmCampaignUrl = Cookies.get("__gtm_campaign_url");
  const gtmReferrerUrl = Cookies.get("__gtm_referrer");
  if (gtmCampaignUrl) {
    queryParams = queryParamsInURL(gtmCampaignUrl);
  }
  const { utm_campaign, utm_content, utm_medium, utm_source, utm_term } =
    queryParams;

  return {
    userId: user.id,
    userIdAuthHash: user.idAuthHash,
    uuid: user.uuid,
    campaignId: campaign.id,
    userName: user.name,
    campaignName: campaign.name,
    email,
    emailAuthHash,
    subdomain,
    desc,
    address,
    country: country?.name,
    subscriptionPlanId: subscription.plan.id,
    subscriptionPlan: subscription.plan.name,
    subscriptionPlanValidity: timeStampToISOString(subscription.validity),
    emailVerified: timeStampToISOString(verifiedAt),
    lastActive: timeStampToISOString(lastLoginAt),
    userCreatedAt: timeStampToISOString(registrationAt),
    version,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
    referrer: gtmReferrerUrl,
    credits: credits ? parseFloat(parseFloat(credits).toFixed(3)) : 0,
    platform: window.VepaarMobile ? window.VepaarMobile.platform : "web",
  };
};
