<template>
  <Slideover id="list-bulk-actions" title="Bulk Actions" :size="300">
    <div class="vp-p-4 vp-space-y-4">
      <div class="vp-divide-y vp-border vp-rounded-md vp-overflow-hidden">
        <slot></slot>
      </div>
    </div>
  </Slideover>
</template>

<script>
import Slideover from "../slideover/index.vue";

export default {
  components: {
    Slideover,
  },
};
</script>
