<template>
  <div class="vp-h-full">
    <VpListEmpty
      :feature="{
        title: 'WhatsApp Cloud API Required',
        desc: 'Broadcast features is powered by WhatsApp Cloud API. To send messages you must configure your WhatsApp Cloud API.',
      }"
    >
      <template #media>
        <VpIcon
          :name="$options.icons.Whatsapp"
          class="vp-h-14 vp-w-14 vp-mb-3 vp-text-wa-teal-500"
        />
      </template>
      <template #action>
        <VyButton
          class="vp-mt-3 button--primary button--solid button--md button--rounded"
          label="Configure"
          :icon="$options.icons.Settings"
          @click.native="
            checkPermission('INTEGRATION_WHATSAPP_CLOUD_API', 'upsert', () => {
              $router.push({ name: 'whatsapp-cloud-api' });
            })
          "
        />
      </template>
    </VpListEmpty>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Settings, Whatsapp } from "icons/icons.js";

export default {
  icons: {
    Settings,
    Whatsapp,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
};
</script>
