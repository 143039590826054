<template>
  <div
    class="vp-flex vp-flex-col vp-w-full vp-p-4 vp-rounded-md vp-mb-3"
    :class="[$options.colors[color][theme]]"
  >
    <div v-if="title || icon" class="vp-flex vp-items-center">
      <Icon :name="icon" v-if="icon" class="vp-w-6 vp-h-6 vp-mr-1 vp-text-xl" />
      <p class="vp-text-lg">{{ title }}</p>
    </div>
    <div class="[&:not(:empty)]:vp-mt-1">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from "./icon.vue";

export default {
  components: {
    Icon,
  },
  colors: {
    warning: {
      solid: "vp-bg-warning-500 vp-text-white",
      outlined: "vp-border-2 vp-border-warning-500 vp-text-warning-500",
      muted: "vp-bg-warning-100 vp-text-warning-500",
    },
    success: {
      solid: "vp-bg-success-500 vp-text-white",
      outlined: "vp-border-2 vp-border-success-500 vp-text-success-500",
      muted: "vp-bg-success-100 vp-text-success-500",
    },
  },
  props: {
    icon: {},
    title: String,
    color: {
      type: String,
      default: "warning",
    },
    theme: {
      type: String,
      default: "solid",
    },
  },
};
</script>
