<template>
  <fieldset :disabled="!isUpsertPermission || isLimitReached">
    <VForm
      v-bind="$attrs"
      :id="id == '+' ? null : id"
      :get="getItem"
      :save="saveItemWrapper"
      :delete="deleteItemWrapper"
      :archive="archiveItemWrapper"
      ref="vue-form"
      :form-state="formState"
      @update:formState="$emit('update:formState', $event)"
    >
      <template
        #default="{
          mode,
          isCreating,
          isEditing,
          state,
          values,
          setValue,
          saveItem,
          deleteItem,
          archiveItem,
          reset,
          isLoading,
          isSaving,
          isGetting,
          isDeleting,
          isArchiving,
          error,
          errors,
          hasError,
        }"
      >
        <slot
          v-if="feature ? checkPermission(feature, 'read') : true"
          name="custom"
          :state="state"
          :response="response"
          :is-getting="isGetting"
          :set-value="setValue"
          :save-item="saveItem"
          :delete-item="deleteItem"
          :is-deleting="isDeleting"
          :is-saving="isSaving"
          :is-creating="isCreating"
        >
          <div class="v-form vp-pb-3" :style="{ width: width }">
            <!-- Loading -->
            <Shimmer class="vp-p-4" v-if="isGetting" />

            <!-- Errors -->
            <Error v-else-if="hasError" :errors="errors" :error="error" />
            <!-- Upgrade Notice -->
            <LimitReached
              :feature="feature"
              :usage="checkSubscription(feature) || {}"
              :is-extension="false"
              v-else-if="isLimitReached"
            />

            <template v-if="!isGetting">
              <div
                v-if="isItemArchived"
                class="vp-bg-warning-100 vp-p-6 vp-rounded-md vp-mb-6 vp-max-w-md"
              >
                <p class="vp-text-warning-500">
                  This item is archived. Unarchive it to make any changes.
                </p>
                <div class="vp-mt-2 vp-flex vp-flex-nowrap vp-space-x-2">
                  <VyButton
                    label="Unarchive"
                    :icon="$options.icons.Unarchive"
                    @click.native="archiveItem"
                    :loading="isArchiving"
                    class="button--warning button--solid button--md button--rounded"
                  />

                  <VyButton
                    v-if="del"
                    :icon="$options.icons.Delete"
                    @click.native="
                      checkPermission(feature, 'delete', deleteItem)
                    "
                    :loading="isDeleting"
                    class="button--danger button--square button--muted button--md button--rounded"
                  />
                </div>
              </div>
              <ValidationObserver
                ref="formObserver"
                v-slot="{ invalid, reset: resetValidation }"
                slim
              >
                <form
                  @submit.prevent="saveItem"
                  class="v-form__form"
                  :class="formClassList(isItemArchived)"
                  ref="form"
                >
                  <header class="v-form__header">
                    <slot name="header">
                      <PageHeader v-if="title" class="vp-mb-3" :title="title">
                        <p>{{ desc }}</p>
                      </PageHeader>
                    </slot>
                  </header>
                  <div class="vp-grid vp-gap-4">
                    <slot
                      :isCreating="isCreating"
                      :isEditing="isEditing"
                      :state="state"
                      :values="values"
                      :response="response"
                      :setValue="setValue"
                      :isLoading="isLoading"
                      :addToMediaQueue="addToMediaQueue"
                      :mediaPreview="mediaPreview"
                      :addToAPIQueue="addToAPIQueue"
                      :removeFromAPIQueue="removeFromAPIQueue"
                      :saveItem="saveItem"
                      :isSaving="isSaving"
                      :deleteItem="deleteItem"
                    />
                  </div>
                  <footer
                    v-if="footer && !isItemArchived"
                    class="vp-flex vp-flex-nowrap vp-mt-4"
                  >
                    <slot
                      name="footer"
                      :mode="mode"
                      :state="state"
                      :values="values"
                      :reset="reset"
                      :errors="errors"
                      :saveItem="saveItem"
                      :deleteItem="deleteItem"
                      :archiveItem="archiveItem"
                      :isSaving="isSaving"
                      :isGetting="isGetting"
                      :isDeleting="isDeleting"
                      :isArchiving="isArchiving"
                      :isArchived="isItemArchived"
                      :invalid="invalid"
                      :reset-validation="resetValidation"
                    >
                      <VyButton
                        v-if="
                          save &&
                          (feature ? checkPermission(feature, 'upsert') : true)
                        "
                        type="submit"
                        :label="isEditing ? saveLabel : createLabel"
                        :loading="isSaving"
                        :disabled="isSaving"
                        class="button--primary button--solid button--md button--rounded"
                      />

                      <VyButton
                        type="button"
                        v-if="isEditing && archive"
                        :icon="$options.icons.Archive"
                        @click.native="
                          checkPermission(feature, 'delete', archiveItem)
                        "
                        :loading="isArchiving"
                        v-tooltip="'Archive Item'"
                        class="vp-ml-auto button--warning button--square button--muted button--md button--rounded"
                      />

                      <VyButton
                        type="button"
                        v-if="isEditing && del"
                        :class="archive ? 'vp-ml-2' : 'vp-ml-auto'"
                        :icon="$options.icons.Delete"
                        @click.native="
                          feature
                            ? checkPermission(feature, 'delete', deleteItem)
                            : deleteItem()
                        "
                        :loading="isDeleting"
                        v-tooltip="'Delete Item'"
                        class="button--danger button--square button--muted button--md button--rounded"
                      />
                    </slot>
                  </footer>
                </form>
              </ValidationObserver>
            </template>
          </div>
        </slot>
        <UnauthorizedAccess v-else />
      </template>
    </VForm>
  </fieldset>
</template>

<script>
import { Archive, Delete, Unarchive } from "icons/icons.js";
import { mapGetters } from "vuex";

import LimitReached from "../limit-reached.vue";
import PageHeader from "../page-header.vue";
import UnauthorizedAccess from "../unauthorized-access.vue";
import Error from "./error.vue";
import Shimmer from "./shimmer.vue";

export default {
  inheritAttrs: false,
  name: "vue-form",

  icons: {
    Archive,
    Unarchive,
    Delete,
  },

  components: {
    Shimmer,
    Error,
    LimitReached,
    PageHeader,
    UnauthorizedAccess,
  },
  props: {
    id: [Number, String],
    width: String,
    title: String,
    desc: String,
    footer: {
      type: Boolean,
      default: true,
    },
    formState: [Object, Array, String],
    get: Function,
    save: Function,
    del: Function,
    archive: Function,
    saveLabel: {
      type: String,
      default: "Save",
    },
    createLabel: {
      type: String,
      default: "Create",
    },
    notify: {
      type: Boolean,
      default: true,
    },
    notifyMessage: {
      type: String,
      default: "Saved Successfully",
    },
    track: {
      type: Object,
    },
    trackPrefix: String,
    trackEventName: String,
    parentRoute: [String, Object],
    /**
     * collectionCache is used for defining apollo cache fieldName that we need to clear from cache.
     * Add `s` apostrophe on get query name. for example, contact operations: collectionCache's value will be 'contacts'.
     * order -> orders, product -> products, whatsAppGroup -> whatsAppGroups
     */
    collectionCache: {
      type: String,
      default: "",
    },
    cache: {
      type: String,
      default: "",
    },
    mediaFor: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    feature: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      response: null,
      isItemArchived: false,
      isAuthorized: true,

      /**
       * Upload/Delete medias before sending save request.
       */
      mediaQueue: [],

      /**
       * API calling stack queue
       */
      apiQueue: [],
    };
  },

  created() {
    if (this.feature) {
      this.isAuthorized = this.checkPermission(this.feature, "read");
    } else {
      this.isAuthorized = true;
    }
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      checkSubscription: "user/checkSubscription",
    }),
    isLimitReached() {
      if (this.feature == "DOMAIN") {
        return false;
      } else {
        return this.feature
          ? !this.checkSubscription(this.feature)?.hasAccess
          : false;
      }
    },
    isUpsertPermission() {
      return this.feature
        ? this.checkPermission(this.feature, "upsert")
        : this.disabled
          ? false
          : true;
    },
  },

  mounted() {
    if (!this.id || this.id === "true") {
      this.updateBreadcrumb(this.$route.name, this.$route.meta.label);
    } else if (this.id === "+") {
      this.updateBreadcrumb(
        this.$route.name,
        `Create ${this.$route.meta.label}`
      );
    } else {
      this.updateBreadcrumb(
        this.$route.name,
        `${this.$route.meta.label} (${this.id})`
      );
    }
  },

  methods: {
    refresh() {
      this.evictCache();
      this.$refs["vue-form"].init();
    },

    setValue(key, value) {
      this.$refs["vue-form"].setValue(key, value);
    },

    updateBreadcrumb(key, value) {
      this.$root.$emit("breadcrumbs-label", key, value);
    },

    mediaPreview(fieldKey, currentMediaUrl) {
      const toCreate = this.mediaQueue.find(
        (media) => media.fieldKey == fieldKey && media.action == "CREATE"
      );

      const isDeleted = this.mediaQueue.find(
        (media) => media.fieldKey == fieldKey && media.action == "DELETE"
      );

      if (toCreate) {
        return toCreate.value;
      } else if (isDeleted) {
        return null;
      } else {
        return currentMediaUrl;
      }
    },

    addToMediaQueue(fieldKey, action, value) {
      // s-media sends false create event before removing the media
      if (action == "CREATE" && value == null) return;

      const data = {
        fieldKey,
        action,
        value,
      };

      const adding = this.mediaQueue.findIndex(
        (media) => media.fieldKey == fieldKey && media.action == "CREATE"
      );

      if (adding > -1) {
        if (action == "CREATE") {
          this.$set(this.mediaQueue, adding, data);
        }
        if (action == "DELETE") {
          this.$delete(this.mediaQueue, adding);
        }
      } else {
        this.mediaQueue.push(data);
      }
    },

    addToAPIQueue(apiCallFunction) {
      // Comparing parameter function with queue by converting them to string.
      const adding = this.apiQueue.findIndex(
        (apiFunc) => apiFunc.key === apiCallFunction.key
      );
      if (adding > -1) {
        this.$set(this.apiQueue, adding, apiCallFunction);
      } else {
        this.apiQueue.push(apiCallFunction);
      }
    },

    removeFromAPIQueue(apiCallKey) {
      const removing = this.apiQueue.findIndex(
        (apiFunc) => apiFunc.key === apiCallKey
      );

      if (removing > -1) {
        this.apiQueue.splice(removing, 1);
      }
    },

    formClassList(isArchived) {
      const classes = [];
      if (isArchived) {
        classes.push("vp-pointer-events-none vp-opacity-50");
      }
      return classes;
    },

    getItem(id) {
      if (this.isAuthorized) {
        return this.get(id).then(({ res, values }) => {
          if (values.archivedAt) this.isItemArchived = true;
          else this.isItemArchived = false;

          if (typeof res === "object") {
            const keys = Object.keys(res);

            if (keys.indexOf("__typename") !== -1) {
              keys.splice(keys.indexOf("__typename"), 1);
            }

            const payload = res[keys[0]];
            this.response = payload;
          }
          return values;
        });
      } else {
        return new Promise((_, reject) => {
          reject({
            message:
              "Oops!\nUnauthorized, You don't have the access to this feature. Please ask admin for the further approvals.",
          });
        });
      }
    },

    async executeAPIQueue() {
      const request = this.apiQueue.map((api) => api.value());
      return Promise.all(request).then((res) => {
        return res.map((item) => item.data);
      });
    },

    async saveItemWrapper(id, data) {
      const isValid = await this.validateForm();
      if (isValid) {
        this.evictCache();
        const apiQueueProcessedData = await this.executeAPIQueue();
        return this.save(id, data, apiQueueProcessedData).then((res) => {
          if (typeof res === "object") {
            const keys = Object.keys(res);
            const payload = res[keys[0]];
            const action = id ? "Updated" : "Created";
            if (this.track) {
              this.segmentAnalytics(payload, action);
            }

            if (res.id) {
              this.response = payload;
            }
            if (this.notify) {
              this.$vayu.notify({
                title: this.notifyMessage,
                state: "success",
                duration: 2000,
              });
              this.mediaQueue = [];
            }
          }

          return data;
        });
      } else {
        const element = document.querySelector(".field--danger");
        if (element) {
          element.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    },

    deleteItemWrapper(id, data) {
      const isConfirm = confirm("Are you sure you want to delete?");
      this.evictCache();
      if (!isConfirm) {
        return new Promise((resolve) => resolve(null));
      }

      return this.del(id, "delete").then(() => {
        if (this.track) {
          const tempData = { ...this.response };
          if (!tempData.id) {
            tempData.id = id;
          }
          this.segmentAnalytics(tempData, "Deleted");
        }
        return data;
      });
    },

    archiveItemWrapper(id, data) {
      let msg;
      let action;
      if (this.isItemArchived) {
        msg = "Are you sure you want to unarchive item?";
        action = "unarchive";
      } else {
        msg = "Are you sure you want to archive?";
        action = "archive";
      }
      const isConfirm = confirm(msg);

      if (!isConfirm) {
        return new Promise((resolve) => resolve(null));
      }

      this.evictCache();
      return this.archive(id, action).then(() => {
        const action = this.isItemArchived ? "Unarchived" : "Archived";
        this.isItemArchived = !this.isItemArchived;
        if (this.track) {
          const tempData = { ...this.response };
          if (!tempData.id) {
            tempData.id = id;
          }
          this.segmentAnalytics(this.response, action);
        }
        return data;
      });
    },

    evictCache() {
      if (this.collectionCache) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: this.collectionCache,
        });
      }
      if (this.cache) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: this.cache,
        });
      }
    },

    segmentAnalytics(data, action) {
      try {
        const result = {};
        Object.keys(this.track).forEach((item) => {
          // If item is object and we have to use more than one key from same object then we need to use resKey.
          const resKey = this.track[item].resKey || item;
          if (typeof this.track[item] === "string") {
            result[`${this.trackPrefix} ${this.track[item]}`] = this.parseValue(
              data[resKey]
            );
          } else if (this.track[item].value) {
            result[`${this.trackPrefix} ${this.track[item].key}`] =
              this.parseValue(this.track[item].value(data[resKey]));
          } else {
            result[`${this.trackPrefix} ${this.track[item].key}`] =
              this.parseValue(data[resKey]);
          }
        });
        const eventName = `${this.trackPrefix} ${
          this.trackEventName ? this.trackEventName : action
        }`;

        this.$track(eventName, result);
      } catch (error) {
        console.error(error);
      }
    },

    validateForm() {
      return this.$refs.formObserver.validate();
    },

    parseValue(val) {
      if (typeof val === "boolean" || typeof val === "number") {
        return val.toString();
      } else {
        return val || "";
      }
    },
  },
};
</script>
