<template>
  <div
    class="vp-flex"
    :class="{ 'vp-flex-col vp-space-y-1': stack, 'vp-space-x-2': !stack }"
  >
    <Field>
      <Input :before-icon="$options.icons.Calendar">
        <VySelect
          class="vp-text-xs vp-w-28"
          placeholder="Custom"
          :options="durations"
          :value="localDuration"
          @input="setDuration($event)"
      /></Input>
    </Field>

    <Field>
      <Input before="From">
        <VyTextbox
          :value="value && toDatepickerFormat(value[0])"
          type="date"
          @input="input(new Date($event), false)"
          :max="toDatepickerFormat(max)"
      /></Input>
    </Field>

    <Field>
      <Input before="To">
        <VyTextbox
          class="!vp-pl-8"
          :value="value && toDatepickerFormat(value[1])"
          type="date"
          @input="input(false, new Date($event))"
          :max="toDatepickerFormat(max)"
          :min="value && toDatepickerFormat(value[0])"
        />
      </Input>
    </Field>
  </div>
</template>

<script>
import {
  startOfDay,
  endOfDay,
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format,
} from "date-fns";
import { Calendar } from "icons/icons.js";
import Field from "./form/field.vue";
import Input from "./form/input.vue";

export default {
  components: {
    Field,
    Input,
  },

  icons: { Calendar },

  props: {
    stack: Boolean,
    max: {
      type: Number,
      default() {
        return new Date().getTime() / 1000;
      },
    },
    value: Array,
    duration: {
      type: String,
      default: "this_month",
    },
  },

  data() {
    return {
      localDuration: null,
    };
  },

  created() {
    if (!this.value) {
      this.setDuration(this.duration);
    }
  },

  watch: {
    value: {
      handler(res) {
        if (!res[0] && !res[1]) {
          this.setDuration("");
        }
      },
      immediate: true,
    },
  },

  computed: {
    today() {
      return {
        start: new Date(),
        end: new Date(),
      };
    },
    durations() {
      return [
        {
          label: "Today",
          value: "today",
          start: this.today.start,
          end: this.today.end,
        },
        {
          label: "Yesterday",
          value: "yesterday",
          start: sub(this.today.start, { days: 1 }),
          end: sub(this.today.end, { days: 1 }),
        },
        {
          label: "This Week",
          value: "this_week",
          start: startOfWeek(this.today.start),
          end: endOfWeek(this.today.end),
        },
        {
          label: "This Month",
          value: "this_month",
          start: startOfMonth(this.today.start),
          end: endOfMonth(this.today.end),
        },
        {
          label: "This Year",
          value: "this_year",
          start: startOfYear(this.today.start),
          end: endOfYear(this.today.end),
        },
      ];
    },
  },
  methods: {
    toDatepickerFormat(timestamp) {
      if (timestamp) {
        const date = format(new Date(timestamp * 1000), "yyyy-MM-dd");
        return date;
      }
      return null;
    },

    toTimestamp(date) {
      return Math.floor(date.getTime() / 1000);
    },

    input(start, end, duration) {
      let value;
      if (start) {
        start = String(this.toTimestamp(startOfDay(start)));
      }
      if (end) {
        end = String(this.toTimestamp(endOfDay(end)));
      }

      if (start && end) {
        value = [start, end];
      } else if (start === false) {
        value = [this.value?.[0], end || undefined];
      } else if (end === false) {
        value = [start || undefined, this.value?.[1]];
      } else {
        value = [undefined, undefined];
      }
      this.localDuration = duration;
      this.$emit("input", value);
      this.$emit("change", value);
    },

    setDuration(duration) {
      const range = this.durations.find((item) => item.value == duration);
      if (range) {
        const { start, end, value } = range;
        this.input(start, end, value);
      } else {
        this.localDuration = null;
      }
    },
  },
};
</script>
