<template>
  <component :is="as">
    <slot v-if="links" v-bind="links"></slot>
  </component>
</template>

<script>
import get from "./get.gql";

export default {
  props: {
    as: {
      type: String,
      default: "div",
    },
  },
  data() {
    return {
      links: null,
    };
  },
  created() {
    this.$query(get).then(({ data }) => {
      this.links = data.documentLinks;
    });
  },
};
</script>
