<template>
  <Modal
    name="limit-reached"
    _class="vp-w-1/3"
    title="✋ It's time to upgrade!"
  >
    <div class="vp-p-4">
      <p class="vp-text-gray-700">
        You've reached the usage limit. To continue using Vepaar with maximum
        limit, please upgrade your account now. For more information about the
        different pricing plans,
        <a
          class="vp-underline vp-font-bold vp-text-primary-500"
          @click="redirectToSubscription"
        >
          click here.
        </a>
      </p>
      <VyTable
        class="vp-mt-3 vp-w-full table--relaxed table--border-y table--border-edge"
      >
        <template #default>
          <tr>
            <th class="vp-text-start">Feature</th>
            <th>Included</th>
            <th>Remaining</th>
          </tr>
          <tr>
            <td>{{ featureName }}</td>
            <td class="vp-text-center">{{ (usage && usage.limit) || "-" }}</td>
            <td class="vp-text-center">{{ (usage && usage.left) || "-" }}</td>
          </tr>
        </template>
      </VyTable>

      <VyButton
        class="vp-mt-2 button--warning button--solid button--md button--rounded"
        fluid
        :icon="$options.icons.Upgrade"
        label="Upgrade Now"
        @click.native="upgrade"
      />
    </div>
  </Modal>
</template>

<script>
import { startCase, camelCase } from "lodash-es";
import { Upgrade } from "icons/icons.js";
import Modal from "./modal.vue";
import { toSubscription } from "plugins/utils.js";

export default {
  components: {
    Modal,
  },

  icons: {
    Upgrade,
  },
  props: {
    feature: String,
    usage: Object,
    isExtension: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    featureName() {
      return startCase(camelCase(this.feature));
    },
  },
  methods: {
    upgrade() {
      this.redirectToSubscription();
      this.$track("Upgrade Button Clicked", {
        Source: "Popup",
        Feature: this.feature,
      });
    },
    redirectToSubscription() {
      if (this.isExtension) {
        window.open(this.$toWeb("subscription-all"), "_blank");
      } else {
        toSubscription();
      }
    },
  },
};
</script>
