<template>
  <div
    class="vp-px-6 vp-py-4 vp-bg-warning-500 vp-flex vp-flex-nowrap vp-items-center"
    v-if="!isVerified"
  >
    <div>
      <h4 class="vp-text-warning-200 vp-text-lg vp-font-bold">
        Email Not Verified.
      </h4>
      <p class="vp-text-warning-200">
        <span>
          We've sent you an email on
          <b class="vp-text-white">{{ email }}</b> to verify your account.
        </span>
        <span v-if="!resent">
          Click on "Resend" if you've not received an email.
        </span>
      </p>
    </div>
    <div class="vp-ml-auto">
      <VyButton
        v-if="!resent"
        label="Resend Verification Email"
        :loading="resending"
        @click.native="resend()"
        class="button--warning button--muted button--md button--rounded"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import resendVerificationEmail from "./resend-verification.gql";

export default {
  data() {
    return {
      resent: false,
      resending: false,
    };
  },
  computed: {
    ...mapGetters({
      email: "user/email",
      isVerified: "user/isVerified",
    }),
  },
  methods: {
    async resend() {
      this.resending = true;
      try {
        await this.$mutate(resendVerificationEmail, {});
        this.resent = true;
        this.$vayu.notify({
          state: "success",
          title: "Verification Email Sent",
          message:
            "Please check your email and follow the steps to verify the account.",
        });
      } catch (err) {
        this.$vayu.notify({
          state: "danger",
          title: "Error",
          message:
            "Failed to send verification email. Kindly contact to resolve the issue.",
        });
      }
      this.resending = false;
    },
  },
};
</script>
