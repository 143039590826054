<template>
  <VyButton
    type="button"
    class="vp-capitalize status button--sm button--pill button--muted"
    :class="$options.colorclasses[value]"
    :label="value"
  />
</template>

<script>
const colorclasses = {
  active: "button--success",
  complete: "button--success",
  processing: "button--warning",
  created: "button--warning",
  inactive: "button--warning",
};
export default {
  colorclasses,
  props: {
    value: String,
  },
};
</script>
