<template>
  <Input class="vp-w-full" v-bind="$attrs" :loading="isLoading">
    <VySelect
      :value="value"
      :options="options"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
    />
  </Input>
</template>

<script>
import Input from "./input.vue";

export default {
  components: {
    Input,
  },
  inheritAttrs: false,
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: "Select",
    },
    request: {
      type: [Function, Object],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      options: [],
    };
  },
  created() {
    this.get();
  },
  methods: {
    refresh() {
      this.get();
    },

    get() {
      this.isLoading = true;
      this.request()
        .then((res) => {
          this.options = res;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
