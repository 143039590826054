<template>
  <div
    class="vp-text-xs vp-text-danger-500 vp-flex vp-items-center"
    v-if="!item.shouldDisplayOnStore"
  >
    <p>You can't use this item.</p>

    <VPopover
      trigger="hover"
      :delay="{ show: 0, hide: 500 }"
      popover-class="popover--locked-item"
      offset="0"
    >
      <VpIcon
        :name="$options.icons.Help"
        class="vp-ml-1 vp-cursor-pointer vp-w-4 vp-h-4"
      />

      <!-- This will be the content of the popover -->
      <template slot="popover">
        <DocsLink #default="{ productHiddenOnStore }">
          <p class="vp-text-sm">
            This is due to a change in your subscription plan.
          </p>
          <VyButton
            class="button button--sm button--warning button--rounded button--solid vp-mt-2"
            :href="productHiddenOnStore"
            target="_blank"
          >
            Know More
          </VyButton>
        </DocsLink>
      </template>
    </VPopover>
  </div>
</template>

<script>
import { Help } from "icons/icons.js";
import DocsLink from "../docs-link/main.vue";
export default {
  icons: {
    Help,
  },
  props: {
    item: Object,
  },
  components: {
    DocsLink,
  },
};
</script>

<style>
.tooltip.popover.popover--locked-item {
  @apply vp-shadow-2xl;

  .tooltip-inner.popover-inner {
    @apply vp-border-4 vp-border-gray-200;
  }

  .tooltip-arrow.popover-arrow {
    border-color: theme("colors.gray.200");
  }
}
</style>
