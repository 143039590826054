<template>
  <div class="vp-inline-block">
    <input
      type="file"
      @change="input"
      ref="upload"
      name="upload"
      class="vp-hidden"
      :accept="accept"
      id="header-file-button"
    />
    <VyButton
      @click.native="clickOnInput()"
      for="upload"
      class="button--sm button--rounded button--solid button--primary"
      :label="label"
      :loading="loading"
      :icon="icon"
    />
  </div>
</template>

<script>
export default {
  name: "vp-file",
  inheritAttrs: false,
  props: {
    value: [FileList],
    label: String,
    icon: Object,
    loading: Boolean,
    accept: String,
  },

  methods: {
    clickOnInput() {
      document.querySelector("#header-file-button").value = null;
      this.$refs.upload.click();
    },
    input(e) {
      const files = e.target.files;
      let fileName = null;
      Array.from(files).forEach((item) => {
        if (fileName) {
          fileName += ", " + item.name;
        } else {
          fileName = item.name;
        }
      });
      this.fileName = fileName;
      this.$emit("input", files);
    },
  },
};
</script>
