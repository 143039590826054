<template>
  <VpModal name="excel-log" title="Bulk Operation Log" class="vp-w-1/3">
    <div class="vp-p-6" v-if="data">
      <status :value="status" class="vp-mb-4" />

      <VyTable class="vp-w-full table--relaxed table--border-y">
        <template #default>
          <tr>
            <th class="vp-text-start">Row</th>
            <th class="vp-text-start">Field</th>
            <th class="vp-text-start">Error</th>
          </tr>
          <template>
            <template v-for="(row, rowIndex) in data.validation_failed_rows">
              <tr
                :key="`row-${rowIndex}-${colIndex}`"
                v-for="(column, colIndex) in row.column"
              >
                <td>{{ row.row }}</td>
                <td>{{ column.label }}</td>
                <td>
                  <p
                    v-for="(error, errorIndex) in column.errors"
                    :key="`error-${rowIndex}-${colIndex}-${errorIndex}`"
                  >
                    {{ error }}
                  </p>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </VyTable>
    </div>
  </VpModal>
</template>

<script>
import Status from "../process-excel/status.vue";
export default {
  props: {
    data: Object,
    status: String,
  },
  components: {
    Status,
  },
};
</script>
