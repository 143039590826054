<template>
  <VpRequest class="vp-h-full" :get="get" ref="request">
    <template #default="{ res, refresh }">
      <slot :feature="{ ...res, refresh }" />
      <LimitReached
        :is-extension="false"
        :feature="theKey"
        :usage="res && res.usage"
      />
    </template>
  </VpRequest>
</template>

<script>
import feature from "../feature/get.gql";
import LimitReached from "../limit-reached.vue";

export default {
  components: {
    LimitReached,
  },
  props: {
    theKey: String,
  },
  methods: {
    get() {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "features",
      });
      return this.$query(feature, {
        key: this.theKey,
      }).then(({ data }) => data.features.data[0]);
    },
  },
};
</script>
