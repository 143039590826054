<template>
  <div>
    <UnauthorizedAccess v-if="!canHavePermission" class="vp-p-4" />
    <VList
      v-if="canHavePermission"
      :per-page="perPage"
      :attrs="attrs"
      :endpoint="endpoint"
      :filters="{ ...localFilters }"
      @update:filters="handleFilterUpdate"
      :params="{
        ...params,
        ...internalParams(),
      }"
      :version="version"
      ref="list"
      @selection="onSelectionChange(arguments)"
      @res="$emit('res', $event)"
      :request-payload="requestPayload"
    >
      <!-- TOP -->
      <template #error="{ error }">
        <ApiError :data="error" class="vp-p-4 vp-mb-4" />
      </template>
      <template
        #header="{ response, loading, loadingPage, loadingMore, instance }"
      >
        <!-- HEADER -->
        <div
          class="vp-sticky v-list-header vp-top-0 vp-bg-white vp-border-b vp-z-10"
        >
          <LineLoader
            v-if="hostLoading || loadingPage || loadingMore"
            class="vp-absolute vp-bottom-0 vp-left-0 vp-right-0 vp-z-20"
            :class="{ 'vp-top-0': !header }"
          />
          <HeaderShimmer v-if="loading && header" />

          <!--  -->
          <div
            v-else-if="header && checkPermission(feature, 'read')"
            class="vp-flex vp-items-stretch vp-h-12"
          >
            <!-- TITLE -->
            <div
              class="vp-pl-4 vp-pr-6 vp-flex vp-items-center vp-justify-center vp-h-12"
              v-if="title"
            >
              <p class="vp-font-bold vp-leading-none">{{ title }}</p>
              <p
                class="vp-leading-none vp-text-gray-500 vp-ml-1"
                v-if="hasHeaderCount"
              >
                ({{ response && response.count }})
              </p>
            </div>

            <slot name="header-actions"> </slot>

            <!-- BULK ACTIONS -->
            <HeaderButton
              v-if="selections.length > 0"
              class="vp-border-l"
              :class="!search ? 'vp-border-r' : ''"
              :icon="$options.icons.Selection"
              :label="`${selections.length} Selected`"
              @click.native="$slideover.open('list-bulk-actions')"
              ping
            />

            <!-- Search -->
            <VListSearch
              v-if="search"
              class="vp-border-r vp-flex-grow vp-relative"
              :class="title ? 'vp-border-l' : ''"
              v-slot="{ value, set }"
            >
              <input
                class="vp-w-full vp-h-12 vp-px-4 vp-outline-none focus:vp-ring-2 focus:vp-ring-inset focus:vp-ring-gray-200"
                placeholder="Search"
                ref="localSearch"
                type="text"
                :value="value"
                @input="set($event.target.value)"
              />
              <Icon
                class="vp-w-6 vp-h-6 vp-absolute vp-right-4 vp-top-0 vp-bottom-0 vp-my-auto vp-text-gray-300"
                :name="$options.icons.Search"
              />
            </VListSearch>

            <ExportModal
              :title="title"
              v-if="exportEndpoint"
              :endpoint="exportEndpoint"
              :params="{
                ...params,
                filters: {
                  ...filters,
                  ...requestPayload?.staticFilters,
                },
                search: $refs?.localSearch?.value,
              }"
              :feature="exportFeature"
              :historyRoute="exportHistoryRoute"
            />

            <!-- Action Buttons -->
            <div
              class="vp-divide-x vp-flex"
              :class="!search ? 'vp-border-l vp-ml-auto' : ''"
            >
              <HeaderButton
                :icon="$options.icons.Refresh"
                @click.native="refresh()"
              />
              <HeaderButton
                v-if="filters"
                :icon="$options.icons.Filter"
                :ping="isFilterApplied"
                @click.native="$slideover.open(`list-filters-${endpoint}`)"
              />
              <HeaderButton
                v-if="exportEndpoint"
                :icon="$options.icons.Download"
                v-tooltip.bottom="`Export`"
                @click.native="
                  checkPermission(exportFeature, 'read', () =>
                    $vayu.modal.open('export')
                  )
                "
              />
              <HeaderButton
                :icon="$options.icons.Settings"
                v-if="settings && attrs.length !== 0"
                @click.native="$slideover.open(`list-settings-${endpoint}`)"
              />
            </div>
          </div>
        </div>

        <!-- SETTINGS -->
        <Settings :instance="instance" :endpoint="endpoint" />

        <!-- BULK ACTIONS -->
        <Bulk>
          <slot
            name="_bulk"
            :selections="selections"
            :count="selections.length"
            :ids="selections.map((row) => parseInt(row.id))"
          />
        </Bulk>

        <!-- FILTERS -->
        <Slideover :id="`list-filters-${endpoint}`" title="Filters" :size="300">
          <div class="vp-p-4 vp-flex vp-flex-col vp-space-y-6">
            <slot name="filters" />
            <VyButton
              color="danger"
              theme="muted"
              label="Reset"
              @click.native="resetFilters()"
              class="button--danger button--md button--rounded button--muted vp-mt-6"
            />
          </div>
        </Slideover>
      </template>

      <!-- LOADING PAGE -->
      <template #loading-page><span class="vp-absolute"></span></template>

      <!-- EMPTY -->
      <template #empty>
        <slot name="empty">
          <ErrorPage
            class="vp-pt-12"
            :icon="$options.icons.Document"
            title="No Data Found!"
            desc="Try changing filters."
          />
        </slot>
      </template>

      <!-- LOADING -->
      <template #loading>
        <slot name="loading">
          <Shimmer />
        </slot>
      </template>

      <!-- DEFAULT -->
      <template #default="{ response, items }">
        <slot :response="response" :items="items">
          <!-- TABLE -->
          <div class="vp-p-4">
            <VListTable
              class="vpr-table"
              :class="{ 'vpr-table--row-click': rowClick }"
              :reorder="canReorder"
              @rowClick="$emit('rowClick', $event)"
              @reorder="$emit('reorder', $event)"
              :rowClass="rowClass"
            >
              <template #th_after="{ attr, sortBy, sortOrder }">
                <div
                  v-if="attr.name == sortBy"
                  class="vp-ml-1 vp-cursor-pointer"
                >
                  <Icon
                    v-if="sortOrder == 'asc'"
                    :name="$options.icons.SortUp"
                  />
                  <Icon
                    v-else-if="sortOrder == 'desc'"
                    :name="$options.icons.SortDown"
                  />
                </div>
                <!-- Unsorted icon: conditions explainantion -->
                <!-- If Sortable is true -->
                <!-- If sortBy is null OR column name is not same as sortBy -->
                <Icon
                  v-if="attr.sortable && (!sortBy || attr.name !== sortBy)"
                  class="vp-ml-1 vp-cursor-pointer"
                  :name="$options.icons.UnSort"
                  :size="14"
                />
              </template>
              <!-- Header -->
              <template #th_select="{ toggleSelectAll, selectionState }">
                <SelectRow
                  :state="selectionState"
                  index="#"
                  @change="toggleSelectAll()"
                />
              </template>

              <!-- Select -->
              <template #select="{ toggleSelect, isSelected, rowIndex }">
                <SelectRow
                  :state="isSelected ? 'all' : 'none'"
                  :index="rowIndex"
                  @change="toggleSelect()"
                />
              </template>

              <!-- Drag -->
              <template #_drag>
                <Icon
                  v-if="
                    canReorder && feature
                      ? checkPermission(feature, 'reorder')
                      : true
                  "
                  class="v-list-table__drag vp-w-6 vp-h-6 vp-text-gray-400 hover:vp-text-gray-700"
                  title="Drag to Sort"
                  :name="$options.icons.Drag"
                />
              </template>

              <!-- Rows After -->
              <template #body-end>
                <slot name="rows-after" :response="response" />
              </template>

              <!-- Inherit Slots -->
              <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
              >
                <slot :name="name" v-bind="slotData" :response="response" />
              </template>
            </VListTable>
          </div>
        </slot>

        <!-- FOOTER -->
        <div v-if="paginationVisibility(response)">
          <!-- PAGINATION -->
          <div v-if="infinite" class="vp-pt-2">
            <VListLoadMore class="vp-flex vp-items-center vp-justify-center">
              <template #default="{ loadMore, loading }">
                <VyButton
                  type="button"
                  label="Load More"
                  :loading="loading"
                  @click.native="loadMore"
                  class="button--primary button--solid button--md button--rounded"
                />
              </template>
              <template #end>
                <span class="vp-text-gray-600 vp-font-bold">
                  -- That's all --
                </span>
              </template>
            </VListLoadMore>
            <VListCounter
              class="vp-flex vp-items-center vp-justify-center vp-text-xs vp-text-gray-500 vp-p-2"
            />
          </div>
          <VListPagination
            v-else
            class="vp-list-paging vp-flex vp-flex-nowrap vp-divide-x vp-justify-center vp-border-t vp-border-b"
          >
            <template #prev="{ prev, hasPrev }">
              <PaginationButton
                :disabled="!hasPrev"
                @click="prev()"
                :icon="$options.icons.ArrowLeft"
              />
            </template>

            <template #page="{ change, value, isActive }">
              <PaginationButton
                :is-active="isActive"
                @click="change(value)"
                :number="value"
              />
            </template>

            <template #next="{ next, hasNext }">
              <PaginationButton
                :disabled="!hasNext"
                @click="next()"
                :icon="$options.icons.ArrowRight"
              />
            </template>
          </VListPagination>
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import {
  ArrowLeft,
  ArrowRight,
  Document,
  Download,
  Drag,
  Filter,
  Refresh,
  Search,
  Selection,
  Settings,
  SortDown,
  SortUp,
  UnSort,
} from "icons/icons.js";
import { isEqual } from "lodash-es";
import { mapGetters } from "vuex";

import ApiError from "../api-error.vue";
import ErrorPage from "../error-page.vue";
import ExportModal from "../export-modal.vue";
import Icon from "../icon.vue";
import LineLoader from "../line-loader.vue";
import Slideover from "../slideover/index.vue";
import UnauthorizedAccess from "../unauthorized-access.vue";
import Bulk from "./bulk.vue";
import HeaderButton from "./header-button.vue";
import HeaderShimmer from "./header-shimmer.vue";
import SelectRow from "./select-row.vue";
import SettingsView from "./settings.vue";
import Shimmer from "./shimmer.vue";
import PaginationButton from "./pagination-button.vue";

export default {
  icons: {
    Selection,
    Search,
    Filter,
    Download,
    Document,
    Drag,
    Refresh,
    Settings,
    ArrowLeft,
    ArrowRight,
    SortUp,
    SortDown,
    UnSort,
  },

  props: {
    requestPayload: {
      query: null,
      /**
       * Static filters Required for filters which are sent in gql query but not changables from UI
       * This is required to avoid the filter blinking.
       */
      staticFilters: null,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    hasHeaderCount: {
      type: Boolean,
      default: true,
    },
    columns: Array,
    endpoint: String,
    filters: Object,

    params: Object,
    title: String,
    bulk: Boolean,
    reorder: {
      type: Boolean,
      default: false,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    reorderLabel: null,
    settings: {
      type: Boolean,
      default: true,
    },
    /**
     * cache is used for defining apollo cache fieldName that we need to clear from cache.
     * This will be used for cache fieldName first if value given as prop.
     * If value is not given then by default, it will take endpoint value.
     */
    cache: String,
    rowClick: {
      type: Boolean,
      default: true,
    },
    rowClass: {
      type: Function,
      default: () => [],
    },
    exportEndpoint: String,
    exportFeature: String,
    exportHistoryRoute: [String, Object],
    feature: {
      type: String,
      default: "",
    },
    version: {
      type: Number,
      default: 3,
    },
    loading: {
      type: Boolean,
    },
  },

  components: {
    HeaderButton,
    HeaderShimmer,
    Shimmer,
    Settings: SettingsView,
    SelectRow,
    Bulk,
    ExportModal,
    LineLoader,
    ApiError,
    Icon,
    UnauthorizedAccess,
    ErrorPage,
    Slideover,
    PaginationButton,
  },

  data() {
    return {
      localFilters: { ...this.filters },
      defaultFilters: { ...this.filters },
      selections: [],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      checkSubscription: "user/checkSubscription",
      isUserPermitted: "user/isUserPermitted",
    }),

    hostLoading() {
      return this.loading;
    },

    isFilterApplied() {
      return !isEqual(this.localFilters, this.defaultFilters);
    },

    canReorder() {
      return this.reorder && this.filters?.archived != true;
    },

    canHavePermission() {
      return this.checkPermission(this.feature, "read");
    },

    attrs() {
      const attrs = [];

      if (this.bulk) {
        attrs.push({
          name: "select",
          label: "#",
          fix: true,
          rowClick: false,
          type: "string",
        });
      }

      attrs.push(...this.columns);

      if (this.canReorder) {
        attrs.push({
          name: "_drag",
          label: this.reorderLabel || "Drag",
          fix: true,
          type: "string",
        });
      }

      return attrs;
    },
  },

  watch: {
    filters: {
      deep: true,

      handler: function (newValue) {
        this.localFilters = { ...newValue };
      },
    },
  },

  methods: {
    handleFilterUpdate(event) {
      this.$emit("update:filters", event);
    },
    internalParams() {
      return {
        __cache:
          this.$route.query.cache == "false" || this.$route.query.cache == false
            ? false
            : true,
      };
    },

    paginationVisibility(response) {
      if (this.perPage === -1) {
        return false;
      } else if (response?.count > this.perPage) {
        return true;
      } else {
        return false;
      }
    },
    onSelectionChange(data) {
      this.selections = data[0];
    },
    resetFilters() {
      this.localFilters = {
        ...this.defaultFilters,
      };
      this.$emit("update:filters", this.localFilters);
    },

    refresh() {
      if (this.isUserPermitted(this.feature, "read")) {
        // Example `this.endpoint` value:-- GQL:contacts

        if (this.requestPayload && this.requestPayload.query) {
          this.$cache.evict({
            id: "ROOT_QUERY",
            fieldName: this.cache,
          });
        } else {
          const endpointCheck = this.endpoint.split(":");
          const queryName = endpointCheck[1];

          this.$cache.evict({
            id: "ROOT_QUERY",
            fieldName: this.cache ? this.cache : queryName,
          });
        }

        this.$slideover.close();
        this.$emit("refresh");
        this.$refs.list.refresh();
      }
    },
  },
};
</script>

<style>
/* Basic Styling */
.vpr-table {
  width: 100%;

  th,
  td {
    padding: theme("space.2");
  }

  th {
    font-size: theme("fontSize.sm");
    border-bottom: 1px solid theme("colors.gray.200");
    color: theme("colors.gray.500") !important;
    padding-bottom: theme("space.2");
    font-weight: theme("fontWeight.semibold");
  }

  tr + tr {
    td {
      border-top: 1px solid theme("colors.gray.200");
    }
  }

  .sortable-ghost {
    background-color: theme("colors.warning.100");
  }
}

/* Clickable Row */
.vpr-table--row-click {
  tr:hover {
    background: theme("colors.gray.100");
    .vpr-table-select__check {
      display: block;
    }
    .vpr-table-select__index {
      display: none;
    }
  }
  .v-list-table__click {
    cursor: pointer;
  }
}

/* Row Tyoe Styling */
.vpr-table {
  .v-list-table__fix {
    width: 0px;
  }
  .v-list-table__number {
    text-align: end;
  }
  .v-list-table__string {
    text-align: start;
  }
  .v-list-table__date {
    text-align: start;
    white-space: nowrap;
    color: theme("colors.gray.500");
    font-size: theme("fontSize.xs");
  }
  .v-list-table__selected {
    background-color: theme("colors.warning.50") !important;
  }
}

.v-list-table__sortable {
  .v-list-table__head {
    display: inline-flex;
    align-items: center;
  }
}
</style>
