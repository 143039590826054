<template>
  <div class="vp-relative">
    <div
      v-if="loading && overlay"
      class="vp-absolute vp-inset-0 vp-bg-white/70 vp-z-10"
    ></div>
    <Loader
      cover
      class="vp-absolute vp-inset-0 vp-z-20 vp-m-auto"
      v-if="loading"
    />

    <slot></slot>
  </div>
</template>

<script>
import Loader from "./loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    loading: Boolean,
    overlay: Boolean,
  },
};
</script>
