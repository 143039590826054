<template>
  <VyRadioGroup
    v-bind="$attrs"
    :options="options"
    :value="value"
    @input="$emit('input', $event)"
    :class="{
      'vp-space-x-3 vp-flex': !stack,
      'vp-space-y-1': stack,
      'vp-pointer-events-none vp-opacity-50': disabled,
    }"
  >
    <template #default="{ option, name, input }">
      <VyRadio
        class="choice--md"
        :value="option.value"
        :label="option.label"
        :group-value="value"
        :name="name"
        @input="input(option.value)"
      />
    </template>
  </VyRadioGroup>
</template>
<script>
export default {
  props: {
    value: [String, Number, Boolean],
    options: Array,
    name: String,
    stack: Boolean,
    disabled: Boolean,
  },
};
</script>
