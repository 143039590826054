<template>
  <VyTextbox
    v-bind="$attrs"
    :readonly="readonly"
    :value="value"
    :class="{ 'vp-text-gray-400': readonly }"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [String, Number],
    readonly: Boolean,
  },
};
</script>
