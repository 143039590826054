<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4v2Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiFilterVariant",
};
</script>
