<template>
  <div class="vp-relative">
    <VyButton
      :icon="$options.icons.Notification"
      @click.native="
        $router.push({
          name: 'notifications',
          query: { type: 'all' },
        })
      "
      class="vp-ml-2 button--gray button--md"
    />
    <span
      v-if="isNotification"
      class="vp-bg-danger-500 vp-absolute vp-w-[6px] vp-h-[6px] vp-top-[10px] vp-rounded-full vp-flex vp-justify-center vp-items-center vp-right-[19px] vp-leading-none vp-font-bold vp-text-white vp-text-xxs"
    >
    </span>
  </div>
</template>

<script>
import { Notification } from "icons/icons.js";
export default {
  icons: {
    Notification,
  },
  props: {
    isNotification: Boolean,
  },
};
</script>
