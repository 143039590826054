import reorderCollection from "@/graph/reorder";

export default {
  methods: {
    async reorder(requestName, items, payload) {
      const ids = items.map((item) =>
        Number(item?.id || item?.media?.id || item)
      );
      try {
        await this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: requestName,
        });
        await this.$mutate(reorderCollection[requestName], {
          ids,
          ...payload,
        });

        this.$track(`${requestName} Reordered`);
      } catch (err) {
        console.error(err);
        this.$vayu.notify({
          title: "Reordering failed!",
          message: "Please refresh & try again.",
          state: "danger",
        });
      }
    },
  },
};
