<template>
  <Shell v-bind="$attrs" class="sub-shell">
    <!-- Aside -->
    <template #nav>
      <div class="vp-border-r vp-h-full vp-overflow-auto">
        <slot name="before-nav" />

        <div class="vp-p-3">
          <SubNav>
            <slot name="nav" />
          </SubNav>
        </div>

        <slot name="after-nav" />
      </div>
    </template>

    <!-- Main -->
    <template #default>
      <slot>
        <RouterView :key="routerViewKey" />
      </slot>
    </template>
  </Shell>
</template>

<script>
import Shell from "./shell.vue";
import SubNav from "./sub-nav.vue";
export default {
  components: {
    SubNav,
    Shell,
  },
  props: {
    routerViewKey: {
      type: String,
    },
  },
};
</script>
