<template>
  <VyConfirm name="delete" _class="vp-w-1/3 vp-rounded-xl">
    <template #footer="{ cancel, ok, okLoading }">
      <div class="vp-flex vp-justify-end vp-space-x-2 vp-p-4">
        <VyButton
          label="Cancel"
          class="button--lg button--gray button--rounded"
          @click.native="cancel()"
        />

        <VyButton
          label="Yes, Delete"
          class="button--lg button--danger button--rounded button--solid"
          :loading="okLoading"
          @click.native="ok()"
        />
      </div>
    </template>
  </VyConfirm>
</template>

<script>
export default {};
</script>
