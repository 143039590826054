<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3 3h18v4H3zm1 18V8h16v13zm10-7v-3h-4v3H7l5 5l5-5z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiArchiveArrowDown",
};
</script>
