<template>
  <VpButtonSm
    v-if="to || route"
    :size="size"
    :icon="$options.icons.Add"
    :theme="theme"
    @click.native="checkUsage('route')"
    :label="label"
    class="button--primary button--solid button--sm"
  />

  <VpButtonSm
    v-else
    :size="size"
    :icon="$options.icons.Add"
    :theme="theme"
    @click.native="checkUsage()"
    :label="label"
    class="button--primary button--solid button--sm"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { Add } from "icons/icons.js";

export default {
  icons: {
    Add,
  },
  props: {
    to: [String, Object],
    route: String,
    param: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "Add New",
    },
    feature: [String, Object],
    size: {
      type: String,
      default: "sm",
    },
    theme: {
      type: String,
      default: "solid",
    },
    usage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
    }),
  },
  methods: {
    checkUsage(type) {
      const feature =
        typeof this.feature === "string" ? this.feature : this.feature.key;
      this.updatedUsage = this.usage || this.feature.usage;
      this.checkPermission(feature, "upsert", () => {
        this.isSubscriptionLimitExceeded(
          feature,
          false,
          this.updatedUsage,
          () => {
            if (type) {
              this.$router.replace(
                this.to || { name: this.route, params: { [this.param]: "+" } }
              );
            } else {
              this.$emit("click");
            }
          }
        );
      });
    },
  },
};
</script>
