<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M21 11H3V9h18zm0 2H3v2h18z"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiDragHorizontalVariant",
};
</script>
