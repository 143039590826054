<template>
  <VpVueForm
    id="true"
    :fields="[]"
    class="vp-p-6"
    :get="get"
    #default="{ response }"
    width="400px"
    ref="item"
  >
    <VpPageHeader title="Zapier">
      <p>
        Here is a
        <VyButton
          href="https://zapier.com/learn/zapier-quick-start-guide/quick-start-create-zap/"
          target="_blank"
          class="button--secondary"
          label="Link"
        />, which will guide you on how to integrate your Vepaar account with
        other apps via Zapier.
      </p>
    </VpPageHeader>

    <div class="vp-mt-4">
      <VyButton
        label="Go to Zapier"
        target="_blank"
        :icon="$options.icons.OpenInNew"
        @click.native="redirect(response)"
        class="button--secondary button--solid button--md button--rounded"
      />
    </div>
  </VpVueForm>
</template>
<script>
import get from "./get.gql";

import { OpenInNew } from "icons/icons.js";

export default {
  icons: {
    OpenInNew,
  },
  methods: {
    redirect(response) {
      window.location.href = response.redirectUrl;
    },
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.integration?.zapier,
          res: data.integration,
        };
      });
    },
  },
};
</script>
