<template>
  <div class="vp-p-4" v-if="loading && !error">
    <div class="shimmer vp-rounded-xl ratio"></div>
    <div class="shimmer vp-h-12 vp-mt-4 vp-rounded"></div>
    <div class="shimmer vp-h-4 vp-mt-2 vp-rounded"></div>
    <div class="shimmer vp-h-4 vp-mt-2 vp-rounded"></div>
    <div class="shimmer vp-h-6 vp-mt-4 vp-rounded"></div>
    <div class="shimmer vp-h-6 vp-mt-1 vp-rounded"></div>
    <div class="shimmer vp-h-6 vp-mt-1 vp-rounded"></div>
  </div>
  <div class="vp-p-4" v-else-if="profile">
    <VpAvatar
      class="vp-bg-gray-100 vp-rounded-xl"
      size="lg"
      :image="profile.image"
      :name="profile.title"
    />
    <h4 class="vp-mt-2 vp-text-lg vp-font-bold">{{ profile.title }}</h4>
    <p class="vp-text-gray-500">
      {{ profile.desc }}
    </p>
    <VpSubNav class="vp-mt-4">
      <slot />
    </VpSubNav>
  </div>
</template>

<script>
export default {
  props: {
    request: Function,
  },
  data() {
    return {
      loading: false,
      error: null,
      profile: null,
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.error = null;
      this.loading = true;
      try {
        this.profile = await this.request();
      } catch (err) {
        this.error = err;
      }
      this.loading = false;
    },
  },
};
</script>
