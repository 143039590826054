<template>
  <div class="vp-p-6">
    <ProcessExcel
      :mutation-query="process"
      :export-query="exportQuery"
      action="update"
      module="contact"
      feature="CONTACT_BULK_UPLOAD"
    >
      <template #headsup>
        <heads-up />
      </template>
    </ProcessExcel>
  </div>
</template>

<script>
import process from "graph/excel/process.gql";
import exportQuery from "graph/excel/export.gql";
import ProcessExcel from "../../process-excel/main.vue";
import HeadsUp from "./_HeadsUp.vue";

export default {
  data() {
    return {
      process,
      exportQuery,
    };
  },
  components: {
    ProcessExcel,
    HeadsUp,
  },
};
</script>
