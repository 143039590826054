const embedURLs = {
  facebook:
    "https://www.facebook.com/plugins/video.php?show_text=0&width=1600&height=900&href=",
  youtube: "https://www.youtube.com/embed/",
  youtu: "https://www.youtube.com/embed/",
  vimeo: "https://player.vimeo.com/video/",
  dailymotion: "https://www.dailymotion.com/embed/video/",
  wistia: "https://fast.wistia.net/embed/iframe/",
  loom: "https://www.loom.com/embed/", // ?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true.
  tiktok: "https://www.tiktok.com/oembed?url=",
};

const parseProvider = (url) => {
  const match = url.match(/(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i);
  return match ? match[1] : undefined;
};

const parse = (url) => {
  if (typeof url === "undefined") {
    return undefined;
  }
  const provider = parseProvider(url);
  return provider;
};

const match = (url, regex) => {
  const match = url.match(regex);
  return match ? match[1] : undefined;
};

const validate = (url) => {
  const isFBWatch = url.includes("fb.watch");
  const provider = isFBWatch ? "facebook" : parse(url);
  const embedUrl = embedURLs[provider];
  if (!provider && !embedUrl) {
    return undefined;
  }
  const result = {};
  switch (provider) {
    case "facebook":
      result.id = url;
      break;

    case "youtube":
    case "youtu":
      {
        // Match an opaque channel ID
        const channelMatch = match(url, /\/channel\/([\w-]+)/);
        // Match a vanity channel name or a user name. User urls are deprecated and
        // currently redirect to the channel of that same name.
        const channelMatchv2 = match(url, /\/channel\/([\w-]+)/);
        // Match video
        const videoMatch = match(
          url,
          /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i
        );
        if (channelMatch) {
          result.id = channelMatch;
        } else if (channelMatchv2) {
          result.id = channelMatchv2;
        } else {
          result.id = videoMatch;
        }
      }
      break;

    case "vimeo":
      {
        result.id = match(
          url,
          /(?:\/showcase\/\d+)?(?:\/(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+)\/)?videos?))?\/(\d+)/i
        );
      }
      break;

    case "dailymotion":
      {
        result.id = match(url, /(?:\/video|ly)\/([A-Za-z0-9]+)/i);
      }
      break;

    case "wistia":
      {
        result.id = match(url, /(?:(?:medias|iframe)\/|wvideo=)([\w-]+)/);
      }
      break;
  }

  const finalURL = embedUrl + result.id;
  return finalURL;
};

export default validate;
